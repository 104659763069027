import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Button from '../atoms/Button';
import AccountLayer from '../organisms/AccountLayer';
import Toast from '../molecules/Toast';
import Header from '../organisms/Header';
import Content from '../organisms/Content';
import Footer from '../organisms/Footer';
import OffCanvas from '../organisms/OffCanvas';
import SearchLayer from '../organisms/SearchLayer';
import Layer from '../organisms/Layer';
import Tutorial from '../organisms/Tutorial';
import { useAuth } from '../../context/AuthUserContext';
import { useApp } from '../../context/AppContext';
import { tutorials } from '../../data/constants';
import { scrollToElement, getYOffset } from '../../lib/utils';
import styles from '../../styles/components/templates/PageDefault.module.scss';
import { UserFromDecodedIdToken } from '../../services/user';

interface PageDefaultProps {
  user?: UserFromDecodedIdToken | null;
  className?: string;
  children?: any;
  stage?: any;
  dark?: boolean;
  urlParameter?: any;
  authOnly?: boolean;
  isForbidden?: boolean;
  filterSearch?: boolean;
  fullSearch?: boolean;
  fullLayer?: boolean;
  initial?: boolean;
  isLoading?: boolean;
  toggleView?: (view: string) => void;
  layer?: {
    bg?: boolean;
    force?: boolean;
    simple?: boolean;
    closeBtn?: boolean;
    full?: boolean;
    children: any;
  };
  toast?: {
    bg?: boolean;
    simple?: boolean;
    closeBtn?: boolean;
    full?: boolean;
    children: any;
  };
  anchor?: {
    label: string;
    el: string;
    icon: string;
    onClick?: () => void;
  };
  help?: string;
  searchInputPlaceholder?: string;
}

export default function PageDefault(props: PageDefaultProps) {
  const {
    children,
    authOnly,
    help,
    isForbidden,
    initial,
    stage,
    layer,
    toast,
    urlParameter,
    filterSearch,
    fullSearch,
    toggleView,
    anchor,
    dark,
    fullLayer,
    isLoading,
    className,
    searchInputPlaceholder,
  } = props;

  const router = useRouter();
  const { asPath } = router;
  const { t } = useTranslation(['common']);
  const { authLoading, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openLayer, setOpenLayer] = useState(false);
  const [forbidden, setForbidden] = useState(true);
  const [currLayer, setCurrLayer] = useState() as any;
  const [currToast, setCurrToast] = useState() as any;
  const [anchorObj, setAnchorObj] = useState({}) as any;
  const [scrolledToAnchor, setScrolledToAnchor] = useState(false);

  const loggedIn = !!user;
  const access = !!!authOnly || !!(authOnly && loggedIn && !forbidden);
  const layerID = 'vsLayer';
  const classes = clsx(
    styles.pageDefault,
    { [styles.hasStage]: !!stage },
    { [styles.atAnchor]: !!scrolledToAnchor },
    className
  );

  /**
   * Handle Search, OffCanvas & Account layers
   */
  const toggle = (el?: any, redirect?: string) => {
    // console.log('toggle', el);
    switch (el) {
      case 'search':
        setOpenSearch(!openSearch);
        setOpenCanvas(false);
        break;
      case 'login':
      case 'signup':
        // setOpenCanvas(false);
        toggleLayer({
          closeBtn: false,
          bg: false,
          children: (
            <AccountLayer
              currView={el}
              redirect={redirect}
              toggle={toggleLayer}
            />
          ),
        });
        break;
      case 'canvas':
        setOpenCanvas(!openCanvas);
        setOpenSearch(false);
        break;
      default:
        setOpenSearch(false);
        setOpenCanvas(false);
        toggleLayer();
        break;
    }
  };

  /**
   * Handle layers
   */
  const layerTimeoutRef = useRef(null) as any;
  const toggleLayer = useCallback((obj?: any) => {
    clearTimeout(layerTimeoutRef.current);
    const isRendered = document.getElementById(layerID);
    const curr =
      !!obj && !!obj.children
        ? obj
        : !!obj && Object.keys(obj).indexOf('type') > -1
        ? {
            simple: true,
            children: obj,
          }
        : !!obj && typeof obj === 'string'
        ? {
            auto: true,
            center: true,
            children: <p>{obj}</p>,
          }
        : null;
    if (curr && !isRendered) {
      // console.log('openLayer', curr);
      setCurrLayer(curr);
      setOpenLayer(true);
    } else if (!!isRendered) {
      console.log(window.location.href, 'hideLayer');
      setOpenLayer(false);
      clearTimeout(layerTimeoutRef.current);
      layerTimeoutRef.current = setTimeout(() => {
        // console.log('removeLayer');
        setCurrLayer(null);
      }, 500);
    }
  }, []);

  const onHelpClick = useCallback(
    (pageKey: string) => {
      console.log('pageKey', tutorials[pageKey]);
      if (!!tutorials[pageKey]) {
        setCurrLayer({
          auto: true,
          forced: true,
          children: <Tutorial pageKey={pageKey} setLayer={toggleLayer} />,
        });
        setOpenLayer(true);
      } else {
        window.open('mailto:feedback@venuescout.org', '_self');
      }
    },
    [toggleLayer]
  );

  /**
   * On Invite: open Claim Venue Tutorial
   * After login: open CompleteUserForm if user hasn't completed onboarding
   * and if not on privacy policy or terms & conditions page
   * onSubmit: Redirect user to my-businesses if user is currently claiming a venue
   */
  useEffect(() => {
    const autoClaim = window.location.href.includes('autoClaim=1');
    const invite = window.location.href.includes('invite_code');
    const exception = ['/privacy-policy', '/terms-conditions'].some((str) =>
      window.location.href.includes(str)
    );
    if (!exception && !!user && !user.onboarding_complete) {
      // open CompleteUserForm
      toggleLayer({
        closeBtn: false,
        bg: false,
        forced: true,
        children: (
          <AccountLayer
            currView={'complete'}
            redirect={autoClaim ? '/my-businesses' : ''}
            toggle={toggleLayer}
          />
        ),
      });
      setOpenLayer(true);
    } else if (!exception && !autoClaim && !!invite) {
      // open Claim Venue Tutorial
      onHelpClick('venue-claim');
    }
  }, [user, toggleLayer, onHelpClick]);

  /**
   * Handle scrolling
   */
  // Workaround for default scroll-to-top bug
  useEffect(() => {
    const handleStop = () => {
      window.scrollTo(0, 0);
    };
    router.events.on('routeChangeComplete', handleStop);
    return () => {
      router.events.off('routeChangeComplete', handleStop);
    };
  }, [router]);

  // onScroll set body class
  useEffect(() => {
    window.onscroll = () => {
      const isScrolled = window.pageYOffset > 10;
      const isScrolledToAnchor =
        !!anchor && anchorObj.el && window.pageYOffset >= anchorObj.y;
      if ((scrolled && !isScrolled) || (!scrolled && isScrolled)) {
        // console.log("isScrolled", isScrolled);
        setScrolled(isScrolled);
      }
      if (
        (!scrolledToAnchor && isScrolledToAnchor) ||
        (scrolledToAnchor && !isScrolledToAnchor)
      ) {
        // console.log("scrolledToAnchor", isScrolledToAnchor, scrolledToAnchor);
        setScrolledToAnchor(isScrolledToAnchor);
      }
    };
  }, [scrolled, anchor, scrolledToAnchor, anchorObj.el, anchorObj.y]);

  /**
   * Set AnchorLnk Button (i.e. "Get in Contact") triggered in page content
   */
  useEffect(() => {
    if (!!anchor && (anchorObj.y === undefined || anchorObj.y === null)) {
      setAnchorObj({
        ...anchor,
        y: getYOffset(anchor.el),
      });
    }
  }, [anchor, anchorObj.el, anchorObj.y]);

  /**
   * Auto-scroll to anchor
   */
  useEffect(() => {
    const anchor = asPath.split('#')[1] || '';
    const el = document.getElementsByClassName(anchor)[0] as HTMLElement;

    if (!loading && el && !!anchor) {
      console.log(
        'scrollIntoView',
        anchor,
        el,
        process.env.NEXT_PUBLIC_ENVIRONMENT
      );
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [asPath, loading]);

  /**
   * Set loading status
   */
  useEffect(() => {
    // console.log('loading?', isLoading, authOnly, authLoading);
    setLoading(!!(isLoading || (authOnly && authLoading)));
  }, [loggedIn, authLoading, isLoading, authOnly]);

  /**
   * Set user access
   */
  useEffect(() => {
    setForbidden(!!isForbidden);
  }, [isForbidden]);

  /**
   * Set toasts triggered in page content
   */
  useEffect(() => {
    setCurrToast(toast);
  }, [toast]);

  /**
   * Set layers triggered in page content
   */
  useEffect(() => {
    toggleLayer(layer);
  }, [layer, toggleLayer]);

  return (
    <>
      <div className={classes}>
        <Header
          minified={scrolled}
          hidden={openSearch}
          dark={dark || (!!stage && !scrolled)}
          bg={!!!stage || scrolled}
          toggle={toggle}
        />

        <Content
          stage={stage}
          loading={loading}
          loggedIn={loggedIn}
          access={access}
          toggle={(el) => toggle(el)}
        >
          {children}
        </Content>

        <Footer toggle={toggle} loggedIn={loggedIn} />

        {anchor && !loading && (
          <Button
            large
            dark
            label={anchor.label}
            icon={anchor.icon}
            onClick={
              !!anchor.onClick
                ? anchor.onClick
                : () => scrollToElement(anchor.el)
            }
          />
        )}

        {!!help && !!access && (
          <Button
            large
            white
            shadow
            colored
            className={styles.help}
            icon={'help'}
            onClick={() => onHelpClick(help)}
            label={t('common:get-help')}
          />
        )}

        <SearchLayer
          scrolled={scrolled}
          toggleLayer={() => toggle('search')}
          toggleView={toggleView}
          initial={initial}
          isOpen={openSearch}
          filter={filterSearch}
          fullSearch={fullSearch}
          urlParameter={urlParameter}
          searchInputPlaceholder={searchInputPlaceholder}
        />

        <OffCanvas
          noBtn
          dark={dark || (!!stage && !scrolled)}
          scrolled={scrolled}
          isOpen={openCanvas}
          toggle={toggle}
        />

        {!!currLayer && (
          <Layer
            id={layerID}
            className={currLayer.className}
            force={currLayer.forced}
            isOpen={openLayer}
            data={currLayer}
            full={fullLayer || !!currLayer.full}
            toggle={() => {
              toggle();
            }}
          >
            {currLayer.children}
          </Layer>
        )}

        {!!currToast && !loading && (
          <Toast isOpen={!!currToast.children} closeBtn={!!currToast.closeBtn}>
            {currToast.children}
          </Toast>
        )}
      </div>
    </>
  );
}
