import React from 'react';
import Image from 'next/image';
import Media from 'react-media';
import Img from './Img';

interface MediaImageProps {
  src: { l?: string; m?: string; s: string };
  alt?: string;
  className?: string;
  contain?: boolean;
}

export default function MediaImage(props: MediaImageProps) {
  const { src, alt, contain, className } = props;

  return (
    <div className={className}>
      <Media
        queries={{
          s: '(max-width: 767px)',
          m: '(min-width: 768px)',
          l: '(min-width: 1024px)',
        }}
      >
        {(matches) => {
          const currSrc = matches.l
            ? src.l || src.m || src.s
            : matches.m
            ? src.m || src.s
            : src.s;
          return (
            <>
              <Img src={currSrc} alt={alt || ''} contain={contain} />
              {/*
             <Image
              className={className}
              placeholder="blur"
              blurDataURL={currSrc}
              src={currSrc}
              alt={alt}
              layout="fill"
              objectFit="cover"
            />
           */}
            </>
          );
        }}
      </Media>
    </div>
  );
}
