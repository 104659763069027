import React, { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import clsx from 'clsx';
import Form from '../molecules/Form';
import Logo from '../atoms/Logo';
import Input from '../atoms/Input';
import styles from '../../styles/components/molecules/LoginForm.module.scss';
import { confirmSignInLink, sendSignInLink } from '../../lib/apis/auth';

enum Step {
  Email,
  EmailSent,
}

interface LoginFormProps {
  className?: string;
  skip?: number;
  prefill?: any;
  redirect?: string;
  asPage?: boolean;
}

export default function LoginForm(props: LoginFormProps) {
  const { t } = useTranslation(['common']);
  const [data, setData] = useState({} as any);
  const [id, setId] = useState('');
  const [step, setStep] = useState<Step>(Step.Email);
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([] as any);
  const [error, setError] = useState() as any;
  const { skip, redirect, asPage, className } = props;
  const { email } = data;
  const formID = 'login-form';

  const classes = clsx(
    `${formID}-step-${step}`,
    styles.signupForm,
    styles[`step-${step}`],
    { [styles.asPage]: asPage },
    className
  );

  const formText = {
    [Step.Email]: {
      h: t(`common:cta-sign-in-or-up`),
      p: t(`common:login:step-1:p`, { email }),
      cta: t(`common:login:step-1:cta`),
    },
    [Step.EmailSent]: {
      h: t(`common:login:step-2:h`),
      p: t(`common:login:step-2:p`, { email }),
      cta: t(`common:login:step-2:cta`),
    },
  }[step];

  const submit = async (formData?: any) => {
    const email = formData?.email || data.email;
    const code = formData?.code || data.code;
    setData({ email });
    setError('');
    setLoading(true);

    try {
      switch (step) {
        case Step.Email:
          const result = await sendSignInLink(email);
          setId(result);
          setStep(Step.EmailSent);
          break;

        case Step.EmailSent:
          await confirmSignInLink(id, code, redirect || window.location.href);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (skip) {
      setStep(skip);
    }
  }, [skip]);

  return (
    <Form
      center
      id={formID}
      className={classes}
      error={error}
      loading={loading}
      submitLabel={formText.cta}
      submitIcon={step === Step.Email ? 'send' : 'login'}
      onSubmit={submit}
      onFieldError={(arr: any) => setFieldErrors(arr)}
    >
      <h1>
        <Logo noLink large />
        {formText.h}
      </h1>

      <p>{formText.p}</p>

      {step === Step.Email && (
        <>
          <Input
            name="email"
            format="email"
            reset={data.email && !!error}
            icon="email"
            label={t(`common:email`)}
            error={fieldErrors.indexOf('email') > -1}
            defaultValue={data.email || ''}
          />
        </>
      )}

      {step === Step.EmailSent && (
        <>
          <>
            <Input
              name="code"
              format="number"
              reset={data.code && !!error}
              icon="password"
              label={t(`common:otp`)}
              error={fieldErrors.indexOf('code') > -1}
              defaultValue={data.code || ''}
            />
          </>
          <br />
          <Link href="#">
            <a
              onClick={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              <small>{t(`common:login:resend-confirmation`)}</small>
            </a>
          </Link>
          {' | '}
          <Link href="#">
            <a
              onClick={(e) => {
                e.preventDefault();
                setStep(Step.Email);
              }}
            >
              <small>{t(`common:login:try-another-email`)}</small>
            </a>
          </Link>
        </>
      )}

      <br />
    </Form>
  );
}
