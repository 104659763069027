import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import Img from '../atoms/Img';
import Icon from '../atoms/Icon';
import MediaImage from '../atoms/MediaImage';
import styles from '../../styles/components/molecules/Figure.module.scss';

interface FigureProps {
  className?: string;
  href?: string;
  ratio?: string;
  title?: any;
  caption?: any;
  children?: any;
  customCaption?: any;
  img?: string | { s: string; m?: string; l?: string };
  alt?: string;
  label?: string;
  icon?: string;
  round?: boolean;
  border?: boolean;
  center?: boolean;
  dark?: boolean;
  gradient?: boolean;
  loading?: boolean;
  tiny?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  hero?: boolean;
  full?: boolean;
  half?: boolean;
  float?: boolean;
  noHover?: boolean;
  disabled?: boolean;
  onClick?: (e: any) => void;
  onMouseOver?: (e: any) => void;
  onMouseOut?: (e: any) => void;
}

export default function Figure(props: FigureProps) {
  const {
    img,
    alt,
    dark,
    gradient,
    title,
    disabled,
    children,
    caption,
    customCaption,
    href,
    round,
    tiny,
    border,
    center,
    ratio,
    medium,
    large,
    label,
    loading,
    hero,
    half,
    full,
    icon,
    float,
    noHover,
    onClick,
    onMouseOver,
    onMouseOut,
    className,
  } = props;
  const { figure } = styles;
  const size = hero
    ? 'hero'
    : large
    ? 'large'
    : medium
    ? 'medium'
    : tiny
    ? 'tiny'
    : 'small';
  const pointer = !noHover && (!!href || !!onClick);
  const classes = clsx(
    figure,
    styles[size],
    { [styles.disabled]: disabled },
    { [styles.center]: center },
    { [styles.border]: border },
    { [styles.loading]: loading },
    { [styles.round]: round },
    { [styles.half]: half && !full },
    { [styles.full]: full && !half },
    { [styles.float]: float },
    { [styles.dark]: dark },
    { [styles.gradient]: gradient },
    { [styles.hover]: !noHover },
    { [styles.pointer]: pointer },
    { [styles[`ratio-${ratio}`]]: !!ratio },
    className
  );
  // const w = large ? 1280 : medium ? 800 : small ? 480 : 200;
  // const h = large ? 920 : medium ? 600 : small ? 480 : 200;
  // const fallback = `https://via.placeholder.com/${w}x${h}/dcecf4/aebdc4?text=${size}+card`;
  // const currImg: any = !!!img && !icon ? {s: fallback, m: fallback, l: fallback} : !icon ? img : false;
  const currFigure = (
    <figure>
      <div>
        {!!img && typeof img === 'string' ? (
          <Img src={img} ratio={ratio} />
        ) : !!img ? (
          <MediaImage src={img as any} />
        ) : (
          ''
        )}
        {!!icon && <Icon name={icon} className={styles.icon} />}
        {!!label && <p>{label}</p>}
        {children}
      </div>

      {(!!customCaption || !!title || !!caption) && (
        <figcaption>
          {!!customCaption ? (
            customCaption
          ) : (
            <small>
              {!!title && <b>{title}</b>}
              <br />
              {!!caption && caption}
            </small>
          )}
        </figcaption>
      )}
    </figure>
  );

  return (
    <div
      className={classes}
      onClick={href ? undefined : onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {!!href ? (
        <Link href={href}>
          <a
            onClick={(e: any) => {
              if (disabled) {
                e.preventDefault();
                if (!!onClick) {
                  onClick(e);
                }
              }
            }}
          >
            {currFigure}
          </a>
        </Link>
      ) : (
        currFigure
      )}
    </div>
  );
}
