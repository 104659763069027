import clsx from 'clsx';
import styles from '../../styles/components/molecules/ContentBox.module.scss';

interface ContentBoxProps {
  className?: string;
  children: any;
  dark?: boolean;
  gradient?: boolean;
  slim?: boolean;
  img?: boolean;
  scroll?: boolean;
  shadow?: boolean;
  border?: boolean;
  autoHeight?: boolean;
  fullHeight?: boolean;
  center?: boolean;
  vCenter?: boolean;
}

export default function ContentBox({
  dark,
  shadow,
  scroll,
  fullHeight,
  img,
  slim,
  gradient,
  autoHeight,
  border,
  center,
  vCenter,
  children,
}: ContentBoxProps) {
  const { contentbox } = styles;
  const classes = clsx(
    contentbox,
    'contentbox',
    { [styles.dark]: dark },
    { [styles.gradient]: gradient },
    { [styles.scrollable]: scroll },
    { [styles.shadow]: shadow },
    { [styles.border]: border },
    { [styles.slim]: slim },
    { [styles.autoHeight]: autoHeight },
    { [styles.fullHeight]: fullHeight },
    { [styles.img]: img },
    { [styles.center]: center },
    { [styles.vCenter]: vCenter }
  );

  return (
    <div className={classes}>
      <div>{children}</div>
    </div>
  );
}
