import React, { useState } from 'react';
import Image, { ImageLoaderProps } from 'next/image';
import clsx from 'clsx';
import { getErrorMsg } from '../../lib/utils';
import styles from '../../styles/components/atoms/Img.module.scss';
import { convertImageIDToURL } from '../../lib/apis/image';

interface ImgProps {
  className?: string;
  src: any;
  alt?: string;
  title?: string;
  width?: number;
  height?: number;
  id?: string;
  ratio?: string;
  contain?: boolean;
  blur?: boolean;
}

export const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src;
};

export const cloudflareLoader = ({ src, ...imageParams }: ImageLoaderProps) => {
  if (src.includes('https://imagedelivery.net') === false) {
    return src;
  }

  const supportedParams = ['width', 'height', 'quality'];
  const resizeParams = [];

  for (const [key, value] of Object.entries(imageParams)) {
    if (supportedParams.includes(key) && typeof value !== 'undefined') {
      resizeParams.push(`${key}=${value}`);
    }
  }

  const paramsString = resizeParams.join(',');
  const normalizedSrc = normalizeSrc(src);

  return `${normalizedSrc}/${paramsString}`;
};

export default function Picture(props: ImgProps) {
  const [error, setError] = useState(false);
  const { src, width, height, alt, blur, ratio, contain, className } = props;
  const classes = clsx(
    styles.img,
    { [styles.contain]: !!contain },
    { [styles.ratio]: !!ratio },
    { [styles.error]: error },
    className
  );
  const sizes =
    !!width && !!height ? `${width}x${height}` : error ? `640x480` : '';
  const currSrc = src;
  // error
  // ? `https://via.placeholder.com${sizes}/ecf0f2/ecf0f2` ///001640?text=not+found  //'/assets/pages/whatis_2_mobile.jpeg'
  // : typeof src === "string" ? src : src.id ? convertImageIDToURL(src.id) : "https://via.placeholder.com${sizes}/ecf0f2/ecf0f2";

  const handleError = (error: any) => {
    // fetch(src)
    //   .then((r) => console.log('status', r.status))
    //   .catch((e) => console.error('error', e));
    const errorMsg = getErrorMsg(error);
    console.log('img error', errorMsg);
    setError(true);
  };

  return (
    <span className={classes}>
      <Image
        loader={cloudflareLoader}
        src={currSrc}
        alt={alt || ''}
        height={height}
        width={width}
        layout={!width || contain ? 'fill' : undefined}
        objectFit={contain ? 'contain' : 'cover'}
        // layout={!width ? 'fill' : undefined}
        // objectFit={!width ? 'cover' : undefined}
        onError={handleError}
        placeholder={blur ? 'blur' : undefined}
        blurDataURL={blur ? src : undefined}
      />
    </span>
  );
}
