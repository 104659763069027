import React, { useState, useEffect, forwardRef, Ref } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Icon from './Icon';
import Button from './Button';
import { autoCorrect, getFieldError } from '../../lib/utils';
import styles from '../../styles/components/atoms/Input.module.scss';

interface InputProps {
  className?: string;
  onChange?: (e: any) => void;
  onChoose?: (e: any) => void;
  onError?: (e: any) => void;
  id?: string;
  name: string;
  type?: string;
  format?: string;
  icon?: string;
  label?: any;
  info?: string;
  unit?: string;
  defaultValue?: string | number | boolean;
  placeholder?: string;
  checkOnce?: boolean;
  mandatory?: boolean;
  dark?: boolean;
  grey?: boolean;
  white?: boolean;
  transparent?: boolean;
  error?: boolean;
  full?: boolean;
  half?: boolean;
  third?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  float?: boolean;
  add?: boolean;
  autoComplete?: string;
  invalid?: boolean;
  disabled?: boolean;
  optional?: boolean;
  convertTo?: string;
  options?: any;
  min?: any;
  max?: any;
  suggestions?: any;
  reset?: boolean;
  maxLength?: number;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
}

const Input = forwardRef(function Input(
  props: InputProps,
  ref: Ref<HTMLInputElement>
) {
  const { t } = useTranslation(['common']);
  const {
    id,
    name,
    type,
    icon,
    optional,
    unit,
    disabled,
    options,
    error,
    checkOnce,
    info,
    format,
    placeholder,
    add,
    invalid,
    dark,
    grey,
    white,
    defaultValue,
    suggestions,
    onChange,
    onChoose,
    onError,
    label,
    reset,
    full,
    half,
    third,
    float,
    small,
    medium,
    large,
    transparent,
    maxLength,
    autoComplete,
    convertTo,
    min,
    max,
    mandatory,
    className,
  } = props;
  const h = large ? 'large' : medium ? 'medium' : small ? 'small' : '';
  const color = dark
    ? 'dark'
    : grey
    ? 'grey'
    : white
    ? 'white'
    : transparent
    ? 'transparent'
    : '';
  const [value, setValue] = useState() as any;
  const [focus, setFocus] = useState(false);
  const [currError, setCurrError] = useState(false);
  const [pwShow, togglePW] = useState(false);
  const [checked, setChecked] = useState(false);
  const [choice, setChoice] = useState(0) as any;
  const toggle = type === 'checkbox' || format === 'boolean';
  const numbers =
    type === 'number' || ['float', 'int', 'number'].indexOf(format || '') > -1;
  const currType =
    pwShow || toggle || numbers ? 'text' : type || format || 'text';
  const inputMax = unit === '%' ? 100 : numbers ? 999999 : maxLength || 1200;
  const isMax = value === inputMax || (value || '').length === inputMax;
  const currValue = !!value && value !== '-' ? value : '';
  const placeHolder =
    !!currError && focus
      ? t('common:form-input-invalid')
      : focus || currValue
      ? placeholder
      : '';

  const classes = clsx(
    styles.input,
    styles[h],
    styles[currType],
    styles[color],
    { [styles.focus]: focus || currValue },
    { [styles.error]: !!currError || invalid },
    { [styles.add]: add },
    { [styles.disabled]: disabled },
    { [styles.info]: !!info },
    { [styles.isMax]: !!isMax },
    { [styles.float]: float },
    { [styles.third]: !half && !full && third },
    { [styles.half]: !full && !third && half },
    { [styles.full]: !half && !third && full },
    { [styles.checked]: checked },
    { [styles.active]: currValue },
    { [styles.date]: type === 'date' },
    { [styles.mandatory]: mandatory },
    // {[styles.select]: !!options},
    { [styles.suggestions]: !!suggestions },
    {
      [styles.expanded]:
        !!suggestions &&
        !!suggestions.length &&
        !!currValue &&
        currValue !== suggestions[choice || 0].value,
    },
    className
  );

  // Yes/No Switch
  const handleClick = (e: any) => {
    e.stopPropagation();
    const toggleLabels = ['false', 'true'].map((el: any) => t(`common:${el}`));
    setChecked(!checked);
    setValue(checked ? toggleLabels[0] : toggleLabels[1]);
    if (onChange) {
      onChange({ [name]: !checked });
    }
  };

  // Text
  const handleChange = (e: any) => {
    const field = e.target;
    const val = autoCorrect(field, inputMax);
    const isError = !checkOnce && getFieldError(field, val);
    setCurrError(!!isError);
    setValue(val);
    setChoice();
    if (onChange && !isError) {
      onChange({ [name]: val });
    }
    if (!!onError) {
      onError(isError);
    }
  };

  // Suggestion Click
  const onSuggestionClick = (i: number) => {
    const obj = suggestions[i] || {};
    setValue(obj.value);
    setChoice(i);
    if (!!onChoose) {
      onChoose(obj);
    }
  };

  const remove = (e: any) => {
    setFocus(false);
    setChecked(false);
    setValue('-');
    setChoice();
  };

  const onFocus = (e: any) => {
    if (props.onFocus) {
      props.onFocus(e);
    }
    setFocus(true);
    if (!toggle && value === '-') {
      setValue('');
    }
  };

  const onBlur = (e: any) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
    if (toggle) {
      return;
    }
    setFocus(false);
    if ((!currError && !value) || (value && !value.length)) {
      setCurrError(false);
    }
    if (numbers && (!value || value === '0')) {
      setValue('-');
    }
  };

  // useEffect(() => {
  //   if(!mounted && defaultValue !== undefined && defaultValue !== null){
  //     setMounted(true);
  //     const boolStr = defaultValue.toString();
  //     const checked = defaultValue === t(`common:true`) || boolStr === "true";
  //     const value = typeof defaultValue === 'boolean' ? t(`common:${boolStr}`) : defaultValue;
  //     if(value === "-"){
  //       setFocus(false);
  //     }
  //     setChecked(checked);
  //     setCurrError(false);
  //     setValue(value);
  //   }
  // }, [defaultValue, t, value, name]);

  useEffect(() => {
    const currValue =
      defaultValue || (defaultValue === false ? false : defaultValue || '');
    const value =
      typeof defaultValue === 'boolean'
        ? t(`common:${currValue.toString()}`)
        : defaultValue;
    const checked = value === t(`common:true`);
    if (value === '-') {
      setFocus(false);
    }
    setChecked(checked);
    // setCurrError(false);
    setValue(value);
  }, [defaultValue, t]);

  useEffect(() => {
    setCurrError(!!error);
  }, [error]);

  return (
    <div className={classes}>
      {icon && <Icon name={icon} />}
      <label htmlFor={name}>
        {(!!label || currError) && (
          <span>
            {label} {!!unit && (focus || currValue) && `(${unit})`}
          </span>
        )}
      </label>

      {/* {!!toggle &&
      <input type="checkbox" name={name} checked={checked} onChange={handleClick}/>
      } */}

      {!!options ? ( //currType == "select"
        <select
          id={id}
          name={name}
          required={!optional}
          data-format={format}
          onChange={handleChange}
          value={currValue}
          data-icon={!!icon ? currValue : null}
        >
          {optional && <option value=""></option>}
          {options.map((el: any, i: number) => (
            <option key={i} value={el.value} data-order={el.order}>
              {el.label}
              {!!el.suffix && el.suffix}
            </option>
          ))}
        </select>
      ) : currType === 'textarea' ? (
        <>
          <small>
            <span>{(currValue || '').length}</span>
            {`/${inputMax}`}
          </small>
          <textarea
            id={id}
            data-format={format}
            name={name}
            title={label}
            readOnly={toggle}
            disabled={disabled}
            required={!optional}
            onFocus={onFocus}
            onBlur={onBlur}
            value={currValue}
            onChange={handleChange}
            placeholder={placeHolder}
          />
        </>
      ) : (
        <input
          id={id}
          data-imperial={convertTo || null}
          data-format={format}
          data-checked={checked}
          data-choice={choice}
          name={name}
          title={label}
          readOnly={toggle}
          disabled={disabled}
          required={!optional}
          onFocus={onFocus}
          onBlur={onBlur}
          type={currType}
          value={currValue}
          autoComplete={autoComplete}
          min={min || null}
          max={max || null}
          onChange={handleChange}
          placeholder={placeHolder}
          ref={ref}
        />
      )}

      {!!suggestions && (
        <ul>
          {suggestions.map((el: any, i: number) => (
            <li key={i} onClick={() => onSuggestionClick(i)} role="button">
              {el.icon && <Icon name={el.icon} />}
              <span>{el.label}</span>
            </li>
          ))}
        </ul>
      )}

      <div>
        {!!reset && currValue && (
          <Button icon={'remove'} tiny onClick={remove} />
        )}
        {/* <Button icon={"add"} tiny onClick={() => setValue("-")} />
        <Button icon={"subtract"} tiny onClick={() => setValue("-")} /> */}
        {!!toggle && (!!focus || currValue) && (
          <div className={styles.switch} onClick={handleClick} />
        )}
        {format === 'password' && (
          <Button
            icon={pwShow ? 'hide' : 'show'}
            onClick={() => togglePW(!pwShow)}
          />
        )}
      </div>
      {!!info && <small>{info}</small>}
    </div>
  );
});

export default Input;
