import { Loader } from '@googlemaps/js-api-loader';
import config from '../config';

let loader: Loader;

let controller: AbortController | null;

export async function getGoogle() {
  if (!loader) {
    loader = new Loader({
      apiKey: config.PLACES_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
  }
  return loader.load();
}

export async function geocomplete(
  input: string,
  types: string[] = ['address'],
  limit: number = 10
) {
  if (!loader) {
    loader = new Loader({
      apiKey: config.PLACES_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
  }

  const google = await loader.load();

  if (controller) {
    controller.abort();
    controller = null;
  }

  controller = new AbortController();

  try {
    const results =
      await new google.maps.places.AutocompleteService().getPlacePredictions({
        input,
        types,
      });

    return results;
  } catch (e: any) {
    if (e.name === 'AbortError') {
      return null;
    }
  }
}

export const mapGooglePlaceToLocation = (place: any) => {
  console.log(place);
  const address = Object.fromEntries(
    place.address_components?.map((c: any) => [c.types[0], c.long_name]) || []
  );
  const address_short = Object.fromEntries(
    place.address_components?.map((c: any) => [c.types[0], c.short_name]) || []
  );

  const formattedLong = place.formatted_address
    .split(' ')
    .map((str: string) =>
      address.route?.indexOf(str.replace('.', '')) > -1 ? address.route : str
    )
    .join(' ');

  return {
    lat: place.geometry?.location?.lat().valueOf() || 0,
    lon: place.geometry?.location?.lng().valueOf() || 0,
    city: address.locality || address.colloquial_area || '',
    name: [address.street_number, address.route].filter(Boolean).join(' '),
    street: address.route,
    housenumber: address.street_number,
    suburb: address.locality,
    country: address.country,
    district: address.administrative_area_level2,
    place_id: place.place_id,
    postcode: address.postal_code,
    formatted: formattedLong,
    country_code: address_short.country,
    address_line1: [address.street_number, address.route]
      .filter(Boolean)
      .join(' '),
    address_line2: [
      address.locality,
      address.administrative_area_level1,
      address.postal_code,
      address.country,
    ]
      .filter(Boolean)
      .join(' '),
  };
};
