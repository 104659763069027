import React, { MouseEvent } from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import clsx from "clsx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../atoms/Logo";
import Icon from "../atoms/Icon";
import styles from "../../styles/components/organisms/Footer.module.scss";

interface FooterProps {
  loggedIn?: boolean;
  toggle: (e: string, redirect?: string) => void;
}

export default function Footer(props: FooterProps) {
  const { t } = useTranslation(["common", "pages"]);
  const { loggedIn, toggle } = props;

  return (
    <footer className={styles.footer}>
      <nav role="footer">
        <Container>
          <Row>
            <Col sm={4} md={2}>
              <ul>
                <li>
                  <Logo />
                  <b>{t(`common:about`)}</b>
                </li>
                <li>
                  <Link href="/about">
                    <a>{t(`pages:about:navi`)}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/privacy-policy">
                    <a>{t(`pages:privacy-policy:navi`)}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/terms-conditions">
                    <a>{t(`pages:terms-conditions:navi`)}</a>
                  </Link>
                </li>
              </ul>
            </Col>

            <Col sm={4} md={2}>
              <ul>
                <li>
                  <Icon name="venue" />
                  <Icon name="hotel" />
                  <Icon name="service" />
                  <b>{t(`common:product-services`)}</b>
                </li>
                <li>
                  <Link href="/venue-packages">
                    <a>{t(`pages:venue-packages:navi`)}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/service-provider-package">
                    <a>{t(`pages:service-provider-package:navi`)}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/my-account">
                    <a
                      onClick={
                        loggedIn
                          ? undefined
                          : (e) => {
                              e.preventDefault();
                              toggle("login", "/my-account");
                            }
                      }
                    >
                      {t(`pages:my-account:navi`)}
                    </a>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col sm={4} md={2}>
              <ul>
                <li>
                  <Icon name="laptop" />
                  <Icon name="phone" />
                  <b>{t(`common:partners-media`)}</b>
                </li>
                <li>
                  <Link href="/partners">
                    <a>{t(`pages:partners:navi`)}</a>
                  </Link>
                </li>
                <li>
                  <Link href={t(`pages:newsletter:link`)}>
                    <a target="_blank" rel="noopener, noreferrer">
                      {t(`pages:newsletter:navi`)}
                    </a>
                  </Link>
                </li>
                {/* <li>
                  <Link href={t(`pages:blog:link`)}>
                    <a target="_blank" rel="noopener, noreferrer">
                      {t(`pages:blog:navi`)}
                    </a>
                  </Link>
                </li> */}
              </ul>
            </Col>

            <Col sm={4} md={2}>
              <ul>
                <li>
                  <Icon name="contact" />
                  <b>{t(`common:customer-support`)}</b>
                </li>
                <li>
                  <Link href="/contact">
                    <a>{t(`pages:contact:navi`)}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/faq">
                    <a>{t(`pages:faq:navi`)}</a>
                  </Link>
                </li>
                {/* <li>
                  <Link href="/guides">
                    <a>{t(`pages:guides:navi`)}</a>
                  </Link>
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
      </nav>
    </footer>
  );
}
