import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import Button from '../atoms/Button';
import { getYOffset, toggleScroll } from '../../lib/utils';
import styles from '../../styles/components/organisms/Layer.module.scss';

interface LayerProps {
  id: string;
  className?: string;
  children?: any;
  data: any;
  toggle: (e?: any) => void;
  isOpen: boolean;
  closeBtn?: boolean;
  auto?: boolean;
  center?: boolean;
  simple?: boolean;
  medium?: boolean;
  large?: boolean;
  full?: boolean;
  bg?: boolean;
  force?: boolean;
}

export default function Layer(props: LayerProps) {
  const ref = useRef() as any;
  const [overflow, setOverflow] = useState(false);
  const [open, setOpen] = useState(false);
  const { id, isOpen, full, force, toggle, data, className, children } = props;
  const { bg, simple, auto, center, medium, large, closeBtn } = data;
  const isSimple = simple || bg || auto;
  const small = !bg && !full && !medium && !large && !auto;
  const classes = clsx(
    styles.layer,
    { [styles.open]: open },
    { [styles.center]: center },
    { [styles.overflow]: overflow },
    { [styles.simple]: isSimple },
    { [styles.bg]: isSimple },
    { [styles.auto]: auto && !medium && !full && !small },
    { [styles.small]: small },
    { [styles.medium]: medium && !large && !full && !auto },
    { [styles.large]: large && !medium && !full && !auto },
    { [styles.full]: full },
    className
  );

  const toggleState = () => {
    toggleScroll(!open);
    if (!!toggle) {
      toggle();
    } else {
      setOpen(!open);
    }
  };

  const handleClick = (event: any) => {
    if (force) {
      return;
    }
    event.stopPropagation(); //Element.scrollHeight > Elment.clientHeight
    const el = document.getElementById(id);
    if (!el) {
      return;
    }
    const elDiv = el.getElementsByTagName('DIV')[0];
    if (!(elDiv instanceof HTMLElement)) {
      return;
    }
    const offset = elDiv.getBoundingClientRect();
    const { left, bottom, top, right } = offset;
    const { clientX, clientY, screenX, screenY, pageX, pageY } = event;
    const mouseX = clientX || left;
    const mouseY = clientY || top;
    const isOut =
      mouseX < left || mouseX > right || mouseY < top || mouseY > bottom;
    if (open && isOut) {
      // console.log(
      //   'layer click outside',
      //   event.target,
      //   mouseX + ' | ' + mouseY + ' > ',
      //   top,
      //   right,
      //   bottom,
      //   left
      // );
      toggleState();
    }
  };

  useEffect(() => {
    const target = ref.current;
    const listening = !!target && open && isSimple;
    let timeout: any;
    const onResize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        // console.log(target.scrollHeight, target.clientHeight);
        const overflow = target.scrollHeight > target.clientHeight + 60;
        if (overflow) {
          setOverflow(overflow);
        }
      }, 500);
    };

    const removeListener = () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', onResize);
    };

    if (listening) {
      onResize();
      window.addEventListener('resize', onResize);
    } else {
      removeListener();
    }
    return () => {
      if (listening) {
        removeListener();
      }
    };
  }, [open, isSimple]);

  useEffect(() => {
    setOverflow(false);
    toggleScroll(!isOpen);
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div id={id} className={classes} onClick={handleClick}>
      <div>
        {isSimple ? (
          <Button small onClick={toggleState} white icon={'close'} />
        ) : closeBtn ? (
          <Button medium onClick={toggleState} white icon={'close'} />
        ) : (
          ''
        )}
        {!!isSimple ? <div ref={ref}>{children}</div> : children}
      </div>
    </div>
  );
}
