import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { getGoogle, mapGooglePlaceToLocation } from '../../lib/googleplaces';
import Input from '../atoms/Input';

interface PlacesSearchProps {
  label?: string;
  name?: string;
  type?: string;
  defaultValue?: any;
  optional?: boolean;
  error?: boolean;
  half?: boolean;
  full?: boolean;
  float?: boolean;
  withFields?: boolean;
  onChoose?: (obj: any) => void;
}

export default function PlacesSearch(props: PlacesSearchProps) {
  const { t } = useTranslation(['common']);
  const [prefill, setPrefill] = useState(false);
  const [results, setResults] = useState([]);
  const [service, setService] = useState() as any;
  const [locationData, setLocationData] = useState({}) as any;
  const [touched, setTouched] = useState(false);
  const {
    name,
    type,
    label,
    error,
    defaultValue,
    onChoose,
    optional,
    half,
    full,
    float,
    withFields,
  } = props;
  const { value } = locationData;
  const currType = type || 'address';
  const inputName = name || 'formatted';

  /**
   * Get place data by Google place ID
   */
  const getPlaceByID = useCallback(
    (placeId: string) => {
      const request = {
        placeId,
        language: 'en',
      };

      const service = new google.maps.places.PlacesService(
        document.createElement('div')
      );

      try {
        service.getDetails(request, (place, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            place &&
            place.geometry &&
            place.geometry.location
          ) {
            const locationData = mapGooglePlaceToLocation(place);

            setLocationData(locationData);
            onChoose?.(locationData);
          } else {
            console.error(google.maps.places.PlacesServiceStatus);
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    [onChoose]
  );

  /**
   * Get Google Place Suggestions
   */
  const search = useCallback(
    (term: string, autoSelectMatch?: boolean) => {
      if (!service) {
        return;
      }

      // const predictions = geocomplete(term)
      const setSuggestions = function (
        predictions: google.maps.places.AutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) {
        if (
          status != google.maps.places.PlacesServiceStatus.OK ||
          !predictions
        ) {
          console.error(status);
          return;
        }

        const results = predictions
          .filter(
            (obj: any) =>
              obj.description.includes('Russia') === false &&
              obj.description.includes('Russland') === false
          )
          .map((obj: any) => {
            const arr = obj.description.split(',');
            const city = arr[0];
            arr.shift();
            const rest = arr.join(', ');
            return {
              icon: 'location',
              id: obj.place_id,
              value: obj.description,
              label: (
                <>
                  <span>{city}</span>
                  {!!rest && <span>, {rest}</span>}
                </>
              ),
            };
          }) as any;

        if (autoSelectMatch && !!results[0]) {
          // console.log({ results });
          setPrefill(true);
          getPlaceByID(results[0].id);
          return;
        } else {
          setResults(results);
        }
      };

      // https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
      const googleTypesByType = {
        city: ['(cities)'],
        region: ['(region)'],
      } as any;
      const types = googleTypesByType[currType] || [currType];
      // console.log({ term });
      service.getPlacePredictions(
        {
          input: term,
          types,
          language: 'en',
        },
        setSuggestions
      );
    },
    [service, currType, getPlaceByID]
  );

  /**
   * Init Google Place Library
   */
  useEffect(() => {
    if (service) {
      return;
    }
    (async () => {
      const google = await getGoogle();
      // console.log({ google });
      if (google) {
        const autocomplete = new google.maps.places.AutocompleteService();
        setService(autocomplete);
      }
    })();
  }, [service]);

  /**
   * Prefill search field
   */
  useEffect(() => {
    if (!!service && !!defaultValue && !prefill) {
      // console.log({ defaultValue });
      search(defaultValue, true);
    }
  }, [defaultValue, service, search, prefill]);

  return (
    <>
      <Input
        full={full && !half}
        half={!full && half}
        float={float}
        reset
        autoComplete="off"
        optional={optional}
        name={inputName}
        icon="location"
        label={label || t('common:address')}
        defaultValue={value || defaultValue || ''}
        onChange={(e: any) => search(e[inputName])}
        onChoose={(obj: any) => getPlaceByID(obj.id)}
        suggestions={touched ? results : undefined}
        onFocus={() => setTouched(true)}
        error={error}
        placeholder={
          currType === 'country' ? '' : t(`common:input-${currType}-exp`)
        }
      />
      {withFields &&
        Object.keys(locationData)
          .filter((el) => el !== inputName)
          .map((el: string, i: number) => (
            <input
              key={i}
              name={el}
              type="hidden"
              value={locationData[el]}
              data-format={el}
            />
          ))}
    </>
  );
}
