import React from 'react';
import clsx from 'clsx';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SignUpForm from './SignupForm';
import LoginForm from './LoginForm';
import styles from '../../styles/components/organisms/AccountLayer.module.scss';
import router from 'next/router';

interface AccountLayerProps {
  className?: string;
  currView: string;
  redirect?: string;
  toggle: (view?: any) => void;
}

export default function AccountLayer(props: AccountLayerProps) {
  const { toggle, currView, redirect } = props;
  const classes = clsx(styles.accountLayer);

  return (
    <Container className={classes}>
      <Row>
        <Col xs={8} md={6} className={'offset-md-1'}>
          {currView === 'complete' ? (
            <SignUpForm
              onCancel={() => toggle}
              onSubmit={() => {
                console.log('complete onSubmit', redirect);
                !!redirect ? router.push(redirect) : toggle();
              }}
            />
          ) : (
            <LoginForm redirect={redirect} />
          )}
        </Col>
      </Row>
    </Container>
  );
}
