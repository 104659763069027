import React from 'react';
import clsx from 'clsx';
import Container from 'react-bootstrap/Container';
import Stage from './Stage';
import NoAccess from '../molecules/NoAccess';
// import Loading from '../atoms/Loading';
import styles from '../../styles/components/organisms/Content.module.scss';

interface ContentProps {
  className?: string;
  children?: any;
  stage?: any;
  loading?: boolean;
  loggedIn?: boolean;
  access?: boolean;
  toggle: (el: string) => void;
}

export default function Content(props: ContentProps) {
  const { loading, toggle, stage, access, children, loggedIn, className } =
    props;
  const classes = clsx(
    styles.content,
    { [styles.loading]: loading },
    { [styles.hasStage]: !!stage },
    className
  );

  return (
    <main className={classes}>
      {!!stage && !loading && <Stage hero slides={stage} />}
      <Container>
        {loading ? (
          ''
        ) : !access ? (
          <NoAccess loggedIn={loggedIn} toggle={toggle} />
        ) : (
          children
        )}
      </Container>
    </main>
  );
}
