import React from "react";
import Col from "react-bootstrap/Col";
import clsx from "clsx";
import styles from "../../styles/components/organisms/Section.module.scss";

interface SectionProps {
  className?: string;
  children?: any;
  hero?: boolean;
  h: any;
  p?: any;
  center?: boolean;
  prepend?: any;
}

export default function Section(props: SectionProps) {
  const { section } = styles;
  const { hero, h, p, center, children, prepend, className } = props;
  const classes = clsx(
    section,
    "row",
    { [styles.center]: center },
    { [styles.hero]: hero },
    className
  );
  return (
    <section className={classes}>
      <Col xs={8}>
        {!!prepend && prepend}
        <h1>{h}</h1>
        {p && <p>{p}</p>}
      </Col>
      {!prepend && children}
    </section>
  );
}
