import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AutoComplete from '../molecules/AutoComplete';
import Button from '../atoms/Button';
import SearchOptions from './SearchOptions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  setSearchValues,
  searchValues,
  setSearchQuery,
  searchQuery,
  setLocations,
  searchLocations,
} from '../../features/search/searchSlice';

import { similarity, toggleScroll } from '../../lib/utils';
import styles from '../../styles/components/organisms/SearchLayer.module.scss';
import { geocomplete } from '../../lib/geoapify';
import { autocomplete } from '../../lib/autocomplete';
import { continents, defaultLocations } from '../../constants/search';
import _ from 'lodash';
import { getSearchCount } from '../../lib/apis/search';
import { isMetric } from '../../features/metrics/metricsSlice';

interface SearchLayerProps {
  className?: string;
  toggleLayer?: () => void;
  toggleView?: (view: string) => void;
  isOpen: boolean;
  urlParameter: any;
  initial?: boolean;
  filter: any;
  scrolled: any;
  disableSearchOptions?: boolean;
  fullSearch?: boolean;
  searchInputPlaceholder?: string;
}

export default function SearchLayer(props: SearchLayerProps) {
  const { t } = useTranslation(['common', 'pages']);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const query = useAppSelector(searchQuery) as any;
  const locations = useAppSelector(searchLocations) as any;
  const currSearchValues = useAppSelector(searchValues) as any;
  const [isLoadingDataCount, setIsLoadingDataCount] = useState(false);
  const [dataCount, setDataCount] = useState<number>(0);
  const { searchLayer } = styles;
  const {
    toggleLayer,
    urlParameter,
    toggleView,
    initial,
    fullSearch,
    filter,
    scrolled,
    isOpen,
    searchInputPlaceholder,
  } = props;
  const [open, setOpen] = useState(false);
  const [resultsTypes, setResultsTypes] = useState('');
  const [focus, setFocus] = useState(false);
  const [view, setView] = useState('list');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const isMounted = React.useRef(false);
  const btnLabel =
    open && searchTerm
      ? searchTerm
      : open
      ? t('common:cta-close')
      : filter
      ? t('pages:search:cta-filter')
      : 'Search venues and services';
  const btnIcon = filter && !open ? 'filter' : open ? 'close' : 'search';
  const placeholder = searchTerm || t('pages:search:input');
  const nextView = view === 'list' ? 'map' : 'list';
  const colProps: { xs: number; md: number; lg: number; class: string } = open
    ? { xs: 8, md: 8, lg: 8, class: '' }
    : filter
    ? { xs: 8, md: 4, lg: 2, class: 'offset-md-2  offset-lg-3' }
    : fullSearch
    ? { xs: 8, md: 8, lg: 8, class: '' }
    : { xs: 8, md: 4, lg: 4, class: 'offset-md-2' };
  const classes = clsx(
    searchLayer,
    { [styles.fullSearch]: fullSearch },
    { [styles.open]: open },
    { [styles.focus]: focus },
    { [styles.initial]: initial },
    { [styles.scrolled]: scrolled },
    { [styles.filter]: filter }
  );
  const metricState = useAppSelector(isMetric);

  const toggleOpen = () => {
    if (!open && !searchTerm) {
      setTimeout(setAutoFocus, 600);
    }
    if (!!toggleLayer) {
      toggleLayer();
    } else {
      setOpen(!open);
    }
  };

  const setAutoFocus = () => {
    const input = document.getElementById('smartSearch');
    if (input) {
      input.focus();
    }
  };

  const searchAll = React.useCallback(
    (keyword: string) => {
      setIsLoading(true);

      return geocomplete(keyword)
        .then((result) => {
          if (!result?.features)
            return {
              keywords: [keyword],
              locations: [],
            };

          const newLocations = result.features
            .map((ft: any) => {
              const {
                city,
                state,
                country,
                lat,
                lon,
                result_type,
                label_formatted,
                countryLocation,
              } = ft.properties;

              if (
                !['country', 'city', 'state', 'locality', 'postcode'].includes(
                  result_type
                )
              ) {
                return null;
              }

              let label = [city, state].filter((x) => x).join(', ');

              if (result_type === 'country') {
                label = country;
              }

              return {
                type: 'location',
                label: label_formatted ? label_formatted : label,
                location:
                  countryLocation || result_type === 'country' ? '' : country,
                value: label,
                lat,
                lon,
                location_type: result_type,
              };
            })
            // filter out nulls
            .filter(Boolean)
            // remove duplicates
            .reduce((acc: any, curr: any) => {
              const found = acc.find((x: any) => x.label === curr.label);
              if (!found) {
                acc.push(curr);
              }
              return acc;
            }, []);

          const similarContinents = continents.filter((continent: any) => {
            return similarity(continent.label, keyword) > 0.8;
          });

          return {
            keyword: result.keyword,
            locations: [...similarContinents, ...newLocations],
          };
        })
        .then((results) => {
          return autocomplete(results.keyword).then((items) => {
            if (!items) {
              return results.locations;
            }
            const venues = items.venues.map((el: any) => ({
              ...el,
              type: 'venue',
              label: el.name,
              slug: el.slug,
              location: '', // TBD
            }));
            const services = items.services.map((el: any) => ({
              ...el,
              type: 'service',
              label: el.name,
              slug: el.slug,
              location: '', // TBD
            }));

            return [...results.locations, ...venues, ...services];
          });
        })
        .then((results) => {
          const searchKeyword = inputRef.current?.value;

          if (searchKeyword === keyword) {
            dispatch(setLocations(results));
            dispatch(setSearchQuery({ ...query, keyword }));
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch, query]
  );

  const debouncedSearchAll = React.useMemo(() => {
    return _.debounce(searchAll, 300);
  }, [searchAll]);

  const onAutoComplete = (index: number) => {
    const curr = locations[index] as any;
    const {
      type,
      value,
      lat,
      lon,
      slug,
      country,
      city,
      location_type,
      fillSearch,
    } = curr || {};

    if (fillSearch && inputRef.current) {
      inputRef.current.value = value;
      searchByKeyword(value);
      return;
    }

    setSearchTerm(value);
    setFocus(false);

    if (type === 'location') {
      let queryObj = {
        ...query,
        keyword: value,
        lat,
        lon,
      };
      delete queryObj.country;
      delete queryObj.continent;

      if (location_type === 'country') {
        const countrySlug = value.toLowerCase().replace(/\s/g, '-');
        queryObj = { ...queryObj, country: countrySlug };
      } else if (location_type === 'continent') {
        const continentSlug = value.toLowerCase().replace(/\s/g, '-');
        queryObj = { ...queryObj, continent: continentSlug };
      }

      if (!queryObj.types) {
        queryObj = { ...queryObj, types: 'venues' };
      }

      dispatch(setSearchQuery(queryObj));

      if (props.disableSearchOptions) {
        showResults(queryObj);
      }
    } else if (!!type) {
      if (type === 'venue') {
        router.push(`/venue/${country}/${city}/${slug}`);
      } else if (type === 'service') {
        router.push(`/service-provider/${country}/${city}/${slug}`);
      }
    }

    if (props.disableSearchOptions || type !== 'location') {
      toggleOpen();
    }
  };

  const onPropChange = (obj: any) => {
    const { id, tab, active, enabled, checked, value, values } = obj;
    const sVal = Array.isArray(values) ? values.join(',') : `${value}`; // the current value to be displayed in ui-control
    const qVal = active ? sVal : undefined; // the current value to be used in query
    const key = `${tab}_${id}`; // the current property key/id to be used in query and values
    const isTab = id.indexOf('searchtype-') > -1; // true if tab was clicked instead of a tab property

    const currentSearchValues = { ...searchValues, types: tab };
    dispatch(setSearchValues(currentSearchValues));

    // set/update current query state object
    const newQuery = {
      ...query,
      types: tab,
      [key]: qVal,
    } as any;

    // removes the keys of an inactive control from current query state object
    if (qVal === undefined) {
      delete newQuery[key];
    }

    // removes all keys of unchecked tab from current query state object
    if (isTab && !checked) {
      Object.keys(newQuery).forEach((key) => {
        if (key.indexOf(value) > -1) {
          delete newQuery[key];
        }
      });
    }
    // adds all previously activated keys of checked tab to current query state object
    if (isTab && checked) {
      Object.keys(currSearchValues)
        .filter((key) => key.indexOf(value) > -1)
        .forEach((key) => {
          if (currSearchValues[key].indexOf('#') === -1) {
            newQuery[key] = currSearchValues[key];
          }
        });
    }
    // stores the query object
    dispatch(setSearchQuery(newQuery));

    // stores current filter values independent of the actual query
    // adds # to the current value string if the value was set but its ui control was deactivated
    const newValues = {
      ...currSearchValues,
      [key]: !isTab && (active || key in newQuery) ? sVal : `${sVal}#`,
    } as any;
    // stores the values object
    dispatch(setSearchValues(newValues));
  };

  const showResults = (searchQuery?: any) => {
    router.push({
      pathname: `/search`, //`/search/${searchType}`,
      query: searchQuery || query,
    });
    toggleOpen();
  };

  const searchByKeyword = (keyword: string) => {
    setSearchTerm(keyword);
    setLocations([]);

    if (!keyword.length) return;

    setIsLoading(true);
    debouncedSearchAll(keyword);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    searchByKeyword(value);
  };

  const handleInputFocus = () => {
    setFocus(true);

    if (!locations.length) {
      const keyword = inputRef.current?.value || '';

      if (keyword.length) {
        searchAll(keyword);
      }
    }
  };

  useEffect(() => {
    // dispatch(setSearchQuery(urlParameter));
    // dispatch(setSearchValues(urlParameter));

    if (!!urlParameter && urlParameter.keyword && !query.keyword) {
      dispatch(setSearchQuery(urlParameter));
      dispatch(setSearchValues(urlParameter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toggleScroll(!isOpen);
    setOpen(isOpen);

    if (isOpen && router.pathname.indexOf('/search') === -1) {
      setFocus(true);
      setSearchTerm('');
      dispatch(setLocations(defaultLocations));

      setTimeout(setAutoFocus, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (props.disableSearchOptions) {
      setAutoFocus();
    }
    toggleScroll(!open);
  }, [open, props.disableSearchOptions]);

  useEffect(() => {
    if (!!toggleView) {
      toggleView(view);
    }
  }, [toggleView, view]);

  useEffect(() => {
    if (router.pathname.indexOf('/search') > -1) {
      if (!!toggleLayer && open) {
        toggleLayer();
      } else {
        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    if (isMounted.current && !searchTerm?.length) {
      dispatch(setLocations(defaultLocations));
    } else {
      isMounted.current = true;
    }
  }, [dispatch, searchTerm]);

  useEffect(() => {
    if (query.keyword) {
      setSearchTerm(query.keyword);
    }
  }, [dispatch, query]);

  const buttonClickHandler = () => {
    if (open) return undefined;
    return toggleLayer?.();
  };

  const iconClickHandler = () => {
    if (!open) return undefined;
    return toggleLayer?.();
  };

  // useEffect(() => {
  //   const types =
  //     urlParameter?.types && typeof urlParameter?.types === 'string'
  //       ? urlParameter.types
  //       : 'venues';
  //   if (open) {
  //     dispatch(setSearchValues({ ...urlParameter, types }));
  //   }
  //   setResultsTypes(urlParameter.types);
  // }, [dispatch, open, urlParameter]);

  useEffect(() => {
    if (open) {
      dispatch(setSearchValues({ ...urlParameter }));
    }
    if (urlParameter?.types && typeof urlParameter?.types === 'string') {
      setResultsTypes(urlParameter.types);
    }
  }, [dispatch, open, urlParameter]);

  useEffect(() => {
    setIsLoadingDataCount(true);
    getSearchCount({ ...query, imperial: !!metricState })
      .then((res) => {
        const count =
          parseInt(res.services?.count || '0') +
          parseInt(res.venues?.count || '0');
        setDataCount(count);
      })
      .finally(() => {
        setIsLoadingDataCount(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const parameters = urlParameter || {};
    dispatch(setSearchQuery(parameters));
    dispatch(setSearchValues(parameters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return (
    <Container className={classes}>
      <Row>
        <Col
          xs={colProps.xs}
          md={colProps.md}
          lg={colProps.lg}
          className={colProps.class}
        >
          <Button
            className={styles.searchInputButton}
            large
            full
            shadow
            dark={filter}
            search={!filter}
            white={!filter}
            colored={filter}
            onClick={buttonClickHandler}
            onIconClick={iconClickHandler}
            label={btnLabel}
            icon={btnIcon}
            input={fullSearch}
          >
            {!open && fullSearch && (
              <>
                <span></span>
                {searchInputPlaceholder}
              </>
            )}
          </Button>
          {(filter || fullSearch) &&
            !open &&
            resultsTypes.includes('venues') && (
              <>
                <Button
                  large
                  full
                  dark
                  colored
                  onClick={() => setView(nextView)}
                  label={nextView}
                  icon={nextView}
                />
              </>
            )}
          <input
            className={styles.smartSearch}
            ref={inputRef}
            type="text"
            id="smartSearch"
            value={searchTerm}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
            placeholder={placeholder}
            disabled={!open}
            autoComplete="off"
          />
          <div>
            <AutoComplete
              data={locations}
              isOpen={focus || !searchTerm}
              onClick={onAutoComplete}
              loading={isLoading}
            />
            {!props.disableSearchOptions && (
              <SearchOptions
                className={styles.options}
                query={query}
                values={currSearchValues}
                onChange={onPropChange}
              >
                <div>
                  {dataCount == 0 && (
                    <div className={styles.bottomDescription}>
                      {t('pages:search:no-results')}
                    </div>
                  )}
                  <Row className={styles.showResultsContainer}>
                    <Col xs={6} md={2}>
                      <Button
                        dark
                        large
                        full
                        loading={isLoadingDataCount}
                        disabled={dataCount === 0 || isLoadingDataCount}
                        onClick={() => showResults()}
                        label={
                          dataCount > 0
                            ? `${t('pages:search:cta-results', {
                                count: dataCount,
                                resultsCount: dataCount,
                              })}`
                            : t('pages:search:cta-no-results')
                        }
                        icon="search"
                      />
                    </Col>
                  </Row>
                </div>
              </SearchOptions>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
