import React from 'react';
import { useTranslation } from 'next-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from '../organisms/Section';
import ContentBox from './ContentBox';
import Button from '../atoms/Button';

interface NoAccessProps {
  className?: string;
  loggedIn?: boolean;
  toggle: (el: string) => void;
}

export default function NoAccess(props: NoAccessProps) {
  const { t } = useTranslation(['common', 'pages']);
  const { toggle, loggedIn } = props;

  return (
    <>
      <Section center h={loggedIn ? <>{t('common:no-access')}</> : ''}>
        {loggedIn ? (
          <>
            <Col xs={8} md={3} className={'offset-md-1'}>
              <br />
              <ContentBox border vCenter center fullHeight>
                <h2>{t('pages:my-businesses:description')}</h2>
                {/*<p>{t("common:not-logged:p")}</p> */}
                <br />
                <Button
                  dark
                  medium
                  icon="venue"
                  label={t('pages:my-businesses:navi')}
                  href={'/my-businesses'}
                />
              </ContentBox>
            </Col>
            <Col xs={8} className={'d-md-none'}>
              <br />
            </Col>
            <Col xs={8} md={3}>
              <br />
              <ContentBox border vCenter center fullHeight>
                <h2>{t('pages:my-account:description')}</h2>
                {/*<p>{t("common:not-logged:p")}</p> */}
                <br />
                <Button
                  dark
                  medium
                  icon="account"
                  label={t('pages:my-account:navi')}
                  href={'/my-account'}
                />
              </ContentBox>
            </Col>
          </>
        ) : (
          <>
            <Col xs={8} md={3} className={'offset-md-1'}>
              <br />
              <ContentBox border vCenter center fullHeight>
                <h2>{t('common:not-signed:h')} </h2>
                <p>{t('common:not-signed:p')} </p>
                <br />
                <Button
                  dark
                  medium
                  icon="signup"
                  label={t('common:not-signed:cta')}
                  onClick={() => toggle('signup')}
                />
              </ContentBox>
            </Col>
            <Col xs={8} className={'d-md-none'}>
              <br />
            </Col>
            <Col xs={8} md={3}>
              <br />
              <ContentBox border vCenter center fullHeight>
                <h2>{t('common:not-logged:h')}</h2>
                <p>{t('common:not-logged:p')}</p>
                <br />
                <Button
                  dark
                  medium
                  icon="login"
                  label={t('common:not-logged:cta')}
                  onClick={() => toggle('login')}
                />
              </ContentBox>
            </Col>
          </>
        )}
      </Section>
      <Row>
        <Col>
          <br />
          <br />
        </Col>
      </Row>
    </>
  );
}
