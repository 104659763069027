import React, { useState, useEffect } from 'react';
import Icon from '../atoms/Icon';
import clsx from 'clsx';
import styles from '../../styles/components/molecules/SearchTypeCheck.module.scss';

interface SearchTypeCheckProps {
  className?: string;
  id: string;
  label: string;
  value: string;
  isActive?: boolean | false;
  onClick?: ({}) => void;
  onChange?: ({}) => void;
}

export default function SearchTypeCheck(props: SearchTypeCheckProps) {
  const { searchTypeCheck } = styles;
  const { id, label, value, isActive, onClick, onChange } = props;
  const [enabled, setEnabled] = useState(false);
  const classes = clsx(searchTypeCheck, { [styles.active]: !!isActive });

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (!!onClick) {
      onClick({ id, value });
    }
    setEnabled(true);
  };

  // Callback (only triggered on user interaction (enabled))
  useEffect(() => {
    if (enabled && !!onChange) {
      setEnabled(false);
      onChange({ id, value });
    }
  }, [enabled, id, value, onChange]);

  return (
    <div className={classes} onClick={handleClick}>
      <label>
        <input type="checkbox" checked={isActive} readOnly />
        <span>
          <b>{label}</b>
        </span>
      </label>
      <Icon name={value} />
    </div>
  );
}
