let controller: AbortController | null;

export async function autocomplete(input: string | string[]) {
  if (controller) {
    controller.abort();
    controller = null;
  }

  controller = new AbortController();

  try {
    const keywords = Array.isArray(input) ? input : [input];

    console.log(keywords)

    const response = await fetch(
      `/api/search?keyword=${keywords
        .map((keyword) => encodeURIComponent(keyword))
        .join(',')}`,
      {
        signal: controller.signal,
      }
    );

    const data = await response.json();

    return data;
  } catch (e: any) {
    if (e.name === 'AbortError') {
      return null;
    }
  }
}
