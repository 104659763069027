import React, { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import clsx from 'clsx';
import PlacesSearch from './PlacesSearch';
import Form from '../molecules/Form';
import Offset from './Offset';
import Input from '../atoms/Input';
import Checkbox from '../atoms/Checkbox';
import Logo from '../atoms/Logo';
import { getErrorMsg, getFormData } from '../../lib/utils';
import { useAuth } from '../../context/AuthUserContext';
import styles from '../../styles/components/molecules/SignupForm.module.scss';
import { UserFromDecodedIdToken } from '../../services/user';

interface SignUpFormProps {
  className?: string;
  userID?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export default function SignUpForm(props: SignUpFormProps) {
  const { t } = useTranslation(['common', 'pages']);
  const { user, logOut, identity, updateUser } = useAuth();
  const router = useRouter();
  const [data, setData] = useState({}) as any;
  const [locationData, setLocationData] = useState({}) as any;
  const [customerType, setCustomerType] = useState([]) as any;
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]) as any;
  const [error, setError] = useState() as any;
  const { onSubmit, onCancel, className } = props;
  const { id } = user || {};
  const formID = 'signup-form';

  const classes = clsx(styles.signupForm, className);

  const formText = {
    h: t(`common:signup:step-3:h`),
    p: t(`common:signup:step-3:p`),
    s: t(`common:signup:step-3:s`),
    cta: t(`common:signup:step-3:cta`),
  };

  const formFields: (keyof UserFromDecodedIdToken)[] = [
    'name',
    'family_name',
    'company',
    'job',
    'country',
    'phone',
  ];

  const customerTypeOptions = [
    'exhibition-center',
    'congress-center',
    'convention-center',
    'hotel-meeting',
    'service-provider',
    'multi-venue',
    'sports-venue',
    'cultural-venue',
    'other-venue',
    'exhibitor',
    'visitor',
    'organizer',
    'media',
    'sponsor',
    'association',
    'academic',
    'student',
    'other',
  ].map((el) => ({
    label: t(`common:${el}`),
    value: el,
  }));

  useEffect(() => {
    if (!!user) {
      const localObj = localStorage.getItem('vs-user-form');
      const prefill = localObj ? JSON.parse(localObj) : user || {};
      setCustomerType(prefill.customer_type || []);
      setData(prefill);
    }
  }, [user]);

  const toggleCustomerType = (key: keyof UserFromDecodedIdToken) => {
    setFieldErrors([
      ...fieldErrors.filter((el: string) => el !== 'customer_type'),
    ]);
    const add = customerType.some((el: string) => el === key) ? [] : [key];
    const arr = [...customerType.filter((el: string) => el !== key), ...add];
    if (!customerType || !arr.length) {
      setFieldErrors([...fieldErrors, 'customer_type']);
      setError(t(`common:form-input-error`));
    }
    setCustomerType(arr);
    handleChange();
  };

  const handleCheckbox = (obj: any) => {
    const key = Object.keys(obj)[0];
    const add = obj[key] ? [] : [key];
    const arr = [...fieldErrors.filter((el: string) => el !== key), ...add];
    setFieldErrors(arr);
    handleChange();
  };

  const handleChange = (data?: any) => {
    const formData = getFormData(formID) as any;
    const tmpData = { ...formData, customer_type: customerType };
    localStorage.setItem('vs-user-form', JSON.stringify(tmpData));
  };

  const handleError = (error: any) => {
    const errorMsg = getErrorMsg(error);
    setError(t(`common:form-error`) + ` (${errorMsg})`);
  };

  const submit = async (formData: any) => {
    if (!id) {
      setError('missing user id');
      return;
    }

    if (!identity) {
      setError('You are not signed in.');
    }

    setLoading(true);
    try {
      await updateUser({
        name: formData.name,
        family_name: formData.family_name,
        company: formData.company,
        job: formData.job,
        country: formData.country,
        phone: formData.phone,
        customer_type: customerType,
        newsletter: formData.newsletter,
        onboarding_complete: true,
      });

      complete();
    } catch (err: any) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const complete = () => {
    localStorage.removeItem('vs-user-form');
    setLoading(false);
    setError('');
    if (!!onSubmit) {
      onSubmit();
    }
  };

  const signOut = () => {
    if (!!router.query.mode) {
      router.replace(router.asPath.split('?')[0], undefined, {
        shallow: true,
      });
    }
    logOut();
  };

  if (!user) {
    return <></>;
  }

  return (
    <Form
      center
      checkOnce
      id={formID}
      className={classes}
      error={error}
      loading={loading}
      submitLabel={formText.cta}
      submitIcon={'send'}
      onSubmit={submit}
      onChange={handleChange}
      // onCancel={cancel}
      onFieldError={(arr: any) => {
        if (!customerType || !customerType.length) {
          setFieldErrors([...arr, 'customer_type']);
        } else {
          setFieldErrors(arr);
        }
      }}
    >
      <h1>
        <Logo noLink large />
        {formText.h}
      </h1>
      <p>{formText.p}</p>

      <Offset>
        {formFields.map((key: keyof UserFromDecodedIdToken, i) =>
          key === 'country' ? (
            <PlacesSearch
              half
              float
              key={i}
              name={key}
              type="country"
              label={t(`common:${key}`)}
              defaultValue={data[key] || locationData?.country || ''}
              error={fieldErrors.indexOf(key) > -1}
            />
          ) : (
            <Input
              reset
              half
              key={i}
              placeholder={
                key === 'phone'
                  ? t(`common:input-phone-exp`, {
                      countryCode: locationData?.phoneCode || '49',
                    })
                  : ''
              }
              unit={key === 'phone' ? t(`common:input-phone-help`) : ''}
              optional={['job', 'phone'].indexOf(key) > -1}
              name={key}
              format={key}
              icon={key}
              label={t(`common:${key}`)}
              error={fieldErrors.indexOf(key) > -1}
              defaultValue={(data[key] || '') as string}
            />
          )
        )}
      </Offset>

      {!!formText.s && (
        <span>
          <small>{formText.s}</small>
        </span>
      )}
      <br />
      <br />
      {t('common:describe-business')}

      <Offset>
        <br />
        {customerTypeOptions.map((el: any, i: number) => (
          <Checkbox
            key={i}
            optional
            half
            error={fieldErrors.indexOf('customer_type') > -1}
            name={el.value}
            label={t(`common:${el.value}`)}
            defaultValue={data.customer_type?.indexOf(el.value) > -1}
            onChange={() => {
              toggleCustomerType(el.value);
            }}
          />
        ))}
      </Offset>

      <div>
        <br />
        <br />

        <Checkbox
          name="privacy"
          error={fieldErrors.indexOf('privacy') > -1}
          onChange={handleCheckbox}
          defaultValue={!!data.privacy}
          label={
            <span>
              {t('common:accept-privacy')}
              <Link href="/privacy-policy">
                <a target="_blank" rel="noopener,noreferrer">
                  {t('pages:privacy-policy:navi')}
                </a>
              </Link>
            </span>
          }
        />
        <Checkbox
          name="terms"
          error={fieldErrors.indexOf('terms') > -1}
          onChange={handleCheckbox}
          defaultValue={!!data.terms}
          label={
            <span>
              {t('common:accept-terms')}
              <Link href="/terms-conditions">
                <a target="_blank" rel="noopener,noreferrer">
                  {t('pages:terms-conditions:navi')}
                </a>
              </Link>
            </span>
          }
        />

        <Checkbox
          optional
          name="newsletter"
          defaultValue={!!data.newsletter}
          label={<span>{t('common:accept-news')}</span>}
        />
      </div>
    </Form>
  );
}
