import { ServiceCategoryType } from '@prisma/client';
import { Unit } from '../lib/units';

export const localeFiles = [
  'common',
  'pages',
  'rating',
  'request',
  'service',
  'venue',
  'tutorials',
];

export const currencies = ['EUR', 'USD'];

export const regions = [
  { id: 'asia', label: 'Asia' },
  { id: 'europe', label: 'Europe' },
  { id: 'north-america', label: 'North America' },
  { id: 'south-america', label: 'South America' },
  { id: 'africa', label: 'Africa' },
  { id: 'australia', label: 'Australia' },
];

export const defaultDocExt = [
  'doc',
  'docx',
  'pdf',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
];

export const defaultImageExt = ['gif', 'jpeg', 'png'];

export const imageSizes = {
  s: '640x480',
  m: '768x576',
  l: '1024x768',
  xl: '1280x960',
  xxl: '1600x1200',
  logo: 'logo',
  promo: 'promo',
} as any;

export const imageTypes = [
  'GALLERY',
  'LOGO',
  'SINGLE',
  'image',
  'svg+xml',
  'bmp',
  'tiff',
  'heic',
  'jpeg',
  'png',
  'gif',
  'jpg',
  ...defaultImageExt,
];

export const mimeTypesByExt = {
  gif: 'gif',
  jpg: 'jpeg',
  jpeg: 'jpeg',
  png: 'png',
  bmp: 'bmp',
  tiff: 'tiff',
  doc: 'msword',
  docx: 'wordprocessingml', //vnd.openxmlformats-officedocument.wordprocessingml.document
  pdf: 'pdf',
  ppt: 'powerpoint', // vnd.ms-powerpoint
  pptx: 'presentation', //vnd.openxmlformats-officedocument.presentationml.presentation
  xl: 'ms-excel', //vnd.ms-excel
  xlsx: 'spreadsheetml', //vnd.openxmlformats-officedocument.spreadsheetml.sheet
} as any;

type SearchOptionsType = {
  [key: string]: {
    [key: string]: {
      value: [number, number] | boolean;
      unit?: Unit;
      dbKey?: ServiceCategoryType;
    };
  };
};

export const searchOptions: SearchOptionsType = {
  venues: {
    exhibition_indoor_m2: {
      value: [0, 500000],
      unit: 'sqm',
    },
    conference_rooms: {
      value: false,
    },
    conference_largest_room_pax: {
      value: [0, 50000],
      unit: 'pax',
    },
  },
  services: {
    construction: {
      value: false,
      dbKey: 'CONSTRUCTION',
    },
    design: {
      value: false,
      dbKey: 'DESIGN',
    },
    installation: {
      value: false,
      dbKey: 'INSTALLATION',
    },
    electricity: {
      value: false,
      dbKey: 'ELECTRICITY',
    },
    lighting: {
      value: false,
      dbKey: 'LIGHTING',
    },
    flooring: {
      value: false,
      dbKey: 'FLOORING',
    },
    furniture: {
      value: false,
      dbKey: 'FURNITURE',
    },
    rigging: {
      value: false,
      dbKey: 'RIGGING',
    },
    catering: {
      value: false,
      dbKey: 'CATERING',
    },
    printing: {
      value: false,
      dbKey: 'PRINTING',
    },
    photo_video: {
      value: false,
      dbKey: 'PHOTO_VIDEO',
    },
    registration: {
      value: false,
      dbKey: 'REGISTRATION',
    },
    logistics: {
      value: false,
      dbKey: 'LOGISTICS',
    },
    audio_visual: {
      value: false,
      dbKey: 'AUDIO_VISUAL',
    },
    staff: {
      value: false,
      dbKey: 'STAFF',
    },
    cleaning: {
      value: false,
      dbKey: 'CLEANING',
    },
    travel: {
      value: false,
      dbKey: 'TRAVEL',
    },
    deco: {
      value: false,
      dbKey: 'DECO',
    },
  },
} as any;

export const createOptions = {
  venue: {
    exhibition_indoor_m2: [0, 500000],
    exhibition_outdoor_m2: [0, 500000],
    halls_count: [1, 40],
    conference_total_pax: [0, 100000],
    conference_rooms_count: [0, 500],
    conference_largest_room_pax: [1, 50000],
  },
  service: {
    construction: false,
    design: false,
    installation: false,
    electricity: false,
    lighting: false,
    flooring: false,
    furniture: false,
    rigging: false,
    catering: false,
    printing: false,
    photo_video: false,
    registration: false,
    logistics: false,
    audio_visual: false,
    staff: false,
    cleaning: false,
    travel: false,
    deco: false,
    other: false,
  },
} as any;

// export const defaultValue = "-";
// export const defaultValueByKey = {};

// Venue result list settings
export const resultsTabs = ['venues', 'services'];
export const resultsInfo = {
  venues: ['exhibition_indoor_m2', 'conference_largest_room_pax'],
  services: [],
};
export const resultsSort = {
  // Venues: ['name', ...resultsInfo['Venues'], 'rating'],
  // Services: ['name', ...resultsInfo['Services'], 'rating'],
  venues: ['name', ...resultsInfo['venues']],
  services: ['name', ...resultsInfo['services']],
} as any;

// Tutorial Step Keyys
export const tutorials = {
  'venue-claim': [
    'claim-button',
    'sign-up',
    'my-businesses',
    'edit',
    'publish',
  ],
} as any;

/*
 * Dummy Data
 */

export const requestData = [
  {
    id: 'a79erqw0698609gj2434v',
    createdAt: '2022-09-01 23:59:59',
    status: 'unanswered',
    files: [
      {
        id: 'asfdfa',
        name: 'asdfasdf',
        type: 'pdf',
        size: 1244224,
        url: 'asdfasdfasdf',
        description: 'sadfasdfads',
      },
    ],
    family_name: 'Linhardt',
    name: 'Frank',
    email: 'frank.linhardt@qwaiko.de',
    phone: '+2345235234',
    company: 'Qwaiko GmbH',
    event_name: "Frank's Event Pt. 2",
    event_start: '2022-08-31',
    event_end: '2022-09-04',
    event_pax: 2200,
    event_space: 10000,
    event_description: 'yeah yeah yeah',
    venue_id: 'cl7jd10t90108vb8z6a4tmp3n',
    venue_plan: 'PLATINUM',
    venue_name: 'A new test venue',
    venue_page: '/venue/deutschland/berlin/a-new-test-venue',
  },
  {
    id: 'b21erqw0698609gj63465y',
    createdAt: '2022-08-27 23:59:59',
    status: 'proposed',
    files: [
      {
        id: 'asfdfa',
        name: 'asdfasdf',
        type: 'pdf',
        size: 1244224,
        url: 'asdfasdfasdf',
        description: 'sadfasdfads',
      },
    ],
    family_name: 'Linhardt',
    name: 'Frank',
    email: 'frank.linhardt@qwaiko.de',
    phone: '+2345235234',
    company: 'Qwaiko GmbH',
    event_name: "Frank's Event",
    event_start: '2022-09-10',
    event_end: '2022-09-11',
    event_pax: 30,
    event_space: 240,
    event_description: 'yeah yeah yeah',
    venue_id: 'cl7jd10t90108vb8z6a4tmp3n',
    venue_plan: 'BASIC',
    venue_name: 'A new test venue',
    venue_page: '/venue/deutschland/berlin/a-new-test-venue',
  },
  {
    id: 'c21erqw0698609gj63465y',
    createdAt: '2022-05-27 23:59:59',
    status: 'proposed',
    files: [
      {
        id: 'asfdfa',
        name: 'asdfasdf',
        type: 'pdf',
        size: 1244224,
        url: 'asdfasdfasdf',
        description: 'sadfasdfads',
      },
    ],
    family_name: 'Linhardt',
    name: 'Frank',
    email: 'frank.linhardt@qwaiko.de',
    phone: '+2345235234',
    company: 'Qwaiko GmbH',
    event_name: "Frank's Event",
    event_start: '2022-09-10',
    event_end: '2022-09-11',
    event_pax: 60,
    event_space: 400,
    event_description: 'yeah yeah yeah',
    venue_id: 'cl7jd10t90108vb8z6a4tmp3n',
    venue_plan: 'PLATINUM',
    venue_name: 'A new test venue',
    venue_page: '/venue/deutschland/berlin/a-new-test-venue',
  },
  {
    id: 'd21erqw0698609gj63465y',
    createdAt: '2021-12-27 23:59:59',
    status: 'proposed',
    files: [
      {
        id: 'asfdfa',
        name: 'asdfasdf',
        type: 'pdf',
        size: 1244224,
        url: 'asdfasdfasdf',
        description: 'sadfasdfads',
      },
    ],
    family_name: 'Linhardt',
    name: 'Frank',
    email: 'frank.linhardt@qwaiko.de',
    phone: '+2345235234',
    company: 'Qwaiko GmbH',
    event_name: "Frank's Event",
    event_start: '2022-09-10',
    event_end: '2022-09-11',
    event_pax: 20,
    event_space: 120,
    event_description: 'yeah yeah yeah',
    venue_id: 'cl7jd10t90108vb8z6a4tmp3n',
    venue_plan: 'BASIC',
    venue_name: 'A new test venue',
    venue_page: '/venue/deutschland/berlin/a-new-test-venue',
  },
] as any;

export const venuesData = [
  {
    id: 'shenzhen-world-exhibition',
    cat: 'Venues',
    name: 'Shenzhen World Exhibition',
    country: 'China',
    state: 'Guangdong Province',
    city: 'Shenzhen',
    status: 'promoted',
    thumbs: ['/assets/thumbs/carousel_0002_venues_shenzhen.jpg'],
    rating: 4.5,
    rates: 15,
    specs: [
      { id: 'space', label: '', value: 42500, unit: 'qm' },
      { id: 'people', label: '', value: 115000, unit: 'ppl' },
    ],
  },
  {
    id: 'china-international-exhibition-center',
    cat: 'Venues',
    name: 'China International Exhibition Center A',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: 'approved',
    thumbs: ['/assets/thumbs/carousel_0000_venues_shanghai.jpg'],
    rating: 4.4,
    rates: 12,
    specs: [
      { id: 'space', label: '', value: 59000, unit: 'qm' },
      { id: 'people', label: '', value: 60000, unit: 'ppl' },
    ],
  },
  {
    id: 'china-international-exhibition-center',
    cat: 'Venues',
    name: 'International Exhibition Center',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: 'approved',
    thumbs: ['/assets/thumbs/carousel_0001_venues_frankfurt.jpg'],
    rating: 4.1,
    rates: 5,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'china-international-exhibition-center',
    cat: 'Venues',
    name: 'China International Exhibition Center B',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: 'approved',
    thumbs: [],
    rating: 4.4,
    rates: 23,
    specs: [
      { id: 'space', label: '', value: 69000, unit: 'qm' },
      { id: 'people', label: '', value: 80000, unit: 'ppl' },
    ],
  },
  {
    id: 'china-international-exhibition-center',
    cat: 'Venues',
    name: 'China International Exhibition Center C',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 4.2,
    rates: 19,
    specs: [
      { id: 'space', label: '', value: 70000, unit: 'qm' },
      { id: 'people', label: '', value: 100000, unit: 'ppl' },
    ],
  },
  {
    id: 'china-international-exhibition-center',
    cat: 'Hotels',
    name: 'A random hotel',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 3.5,
    rates: 14,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'china-international-exhibition-center',
    cat: 'Services',
    name: 'Random service provider',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 3.2,
    rates: 15,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
];

export const topPicks = [
  {
    title: 'Title #1',
    caption: 'Teaser Text',
    img: '/assets/thumbs/carousel_0000_venues_shanghai.jpg',
    href: '/venue',
  },
  {
    title: 'Title #2',
    caption: 'Teaser Text',
    img: '/assets/thumbs/carousel_0001_venues_frankfurt.jpg',
    href: '/venue',
  },
  {
    title: 'Title #3',
    caption: 'Teaser Text',
    img: '/assets/thumbs/carousel_0002_venues_shenzhen.jpg',
    href: '/venue',
  },
  {
    title: 'Title #4',
    caption: 'Teaser Text',
    img: '/assets/thumbs/carousel_0003_venues_javisnewyork.jpg',
    href: '/venue',
  },
  {
    title: 'Title #5',
    caption: 'Teaser Text',
    img: '/assets/thumbs/carousel_0004_venues_vienna.jpg',
    href: '/venue',
  },
  {
    title: 'Title #6',
    caption: 'Teaser Text',
    img: '/assets/thumbs/carousel_0005_venues_duesseldorf.jpg',
    href: '/venue',
  },
  {
    title: 'Title #7',
    caption: 'Teaser Text',
    img: '/assets/thumbs/carousel_0006_venues_barcelona.jpg',
    href: '/venue',
  },
  {
    title: 'Title #8',
    caption: 'Teaser Text',
    img: '/assets/thumbs/carousel_0007_venues_cologne.jpg',
    href: '/venue',
  },
  { title: 'Title #9', caption: 'Teaser Text', img: '', href: '/venue' },
  { title: 'Title #10', caption: 'Teaser Text', img: '', href: '/venue' },
  { title: 'Title #11', caption: 'Teaser Text', img: '', href: '/venue' },
  { title: 'Title #12', caption: 'Teaser Text', img: '', href: '/venue' },
  { title: 'Title #13', caption: 'Teaser Text', img: '', href: '/venue' },
  { title: 'Title #14', caption: 'Teaser Text', img: '', href: '/venue' },
  { title: 'Title #15', caption: 'Teaser Text', img: '', href: '/venue' },
  { title: 'Title #16', caption: 'Teaser Text', img: '', href: '/venue' },
];

export const venueData = {
  id: 'shenzhen-world-exhibition',
  cat: 'Venues',
  name: 'Shenzhen World Exhibition',
  country: 'China',
  state: 'Guangdong Province',
  city: 'Shenzhen',
  status: 'promoted',
  thumbs: ['/assets/thumbs/carousel_0002_venues_shenzhen.jpg'],
  gallery: ['/assets/thumbs/carousel_0002_venues_shenzhen.jpg'],
  rating: 4.5,
  rateCnt: 15,
  specs: [
    {
      id: 'indoor-space',
      icon: 'indoor',
      cat: 'general',
      label: 'Indoor space',
      value: 50000,
      unit: 'sqm',
    },
    {
      id: 'outdoor-space',
      icon: 'outdoor',
      cat: 'general',
      label: 'Outdoor space',
      value: 175000,
      unit: 'sqm',
    },
    {
      id: 'capacity',
      icon: 'capacity',
      cat: 'general',
      label: 'Capacity',
      value: 50000,
      unit: 'pax',
    },
    {
      id: 'own-events',
      icon: 'events',
      cat: 'general',
      label: 'Organizes own events',
      value: 'Yes',
      unit: '',
    },
    {
      id: 'standard-rate',
      icon: 'srpsqm',
      cat: 'general',
      label: 'Standard rate per sqm',
      value: 999,
      unit: '$',
    },
    {
      id: 'season-discount',
      icon: 'discount',
      cat: 'general',
      label: 'Discount rate off-season',
      value: 25,
      unit: '%',
    },
    {
      id: 'constr-discount',
      icon: 'discount',
      cat: 'general',
      label: 'Discount rate construction and dismantling',
      value: 'Yes',
      unit: '',
    },
    {
      id: 'parking',
      icon: 'parking',
      cat: 'general',
      label: 'Car parking',
      value: 212000,
      unit: 'parking spots',
    },
    {
      id: 'halls',
      icon: 'halls',
      cat: 'general',
      label: 'Number of halls',
      value: 44,
      unit: 'halls',
    },
    {
      id: 'wifi',
      icon: 'wifi',
      cat: 'general',
      label: 'Wi-Fi Availability',
      value: 512,
      unit: 'mBit',
    },
    {
      id: 'conference-avail',
      icon: 'wifi',
      cat: 'general',
      label: 'Are permanent conference facilities available?',
      value: 'Yes',
      unit: '',
    },
    {
      id: 'conference-cap',
      icon: 'capacity',
      cat: 'general',
      label: 'Overall conference capacity',
      value: 50000,
      unit: 'pax',
    },
    {
      id: 'protection',
      icon: 'space',
      cat: 'security',
      label: 'Theme protection',
      value: 'www.myvenue.com/policy',
      unit: '',
    },
    {
      id: 'iso',
      icon: 'space',
      cat: 'security',
      label: 'ISO Certification',
      value: 'ISO Certificate 2623:2021',
      unit: '',
    },
    {
      id: 'sustainability',
      icon: 'space',
      cat: 'security',
      label: 'Sustainability Policy',
      value: 'Yes',
      unit: '',
    },
  ],
};

export const venuePics = [
  {
    small: '/assets/thumbs/carousel_0000_venues_shanghai.jpg',
    large: '/assets/thumbs/carousel_0000_venues_shanghai.jpg',
  },
  {
    small: '/assets/thumbs/carousel_0001_venues_frankfurt.jpg',
    large: '/assets/thumbs/carousel_0001_venues_frankfurt.jpg',
  },
  {
    small: '/assets/thumbs/carousel_0002_venues_shenzhen.jpg',
    large: '/assets/thumbs/carousel_0002_venues_shenzhen.jpg',
  },
  {
    small: '/assets/thumbs/carousel_0003_venues_javisnewyork.jpg',
    large: '/assets/thumbs/carousel_0003_venues_javisnewyork.jpg',
  },
  {
    small: '/assets/thumbs/carousel_0004_venues_vienna.jpg',
    large: '/assets/thumbs/carousel_0004_venues_vienna.jpg',
  },
  {
    small: '/assets/thumbs/carousel_0005_venues_duesseldorf.jpg',
    large: '/assets/thumbs/carousel_0005_venues_duesseldorf.jpg',
  },
  {
    small: '/assets/thumbs/carousel_0006_venues_barcelona.jpg',
    large: '/assets/thumbs/carousel_0006_venues_barcelona.jpg',
  },
  {
    small: '/assets/thumbs/carousel_0007_venues_cologne.jpg',
    large: '/assets/thumbs/carousel_0007_venues_cologne.jpg',
  },
];

export const venueRestaurant = {
  name: '-',
  description: '-',
  capacity: '-',
  operation_hours: '-',
};
export const venueRestaurants = [
  // id: "hotel-id-1",
  // cat: "Hotels",
  // name: "Hotel Presidente",
  // country: "China",
  // state: "",
  // city: "Beijing",
  // status: "promoted",
  // thumbs: ["https://via.placeholder.com/400x277/dcecf4/"],
  // rating: 4.5,
  // rates: 15,
  // specs: [
  //     {id: "space", label: "", value: 72000, unit: "qm"},
  //     {id: "people", label: "", value: 120000, unit: "ppl"},
  //   ]
  // }

  {
    id: 'xy',
    cat: 'catering',
    name: 'ATRIUM',
    description:
      'Treat yourself to a delicious snack, an energising coffee or a refreshing drink. The Atrium self-service restaurant is located above the Mall.',
    specs: [{ id: 'people', label: '', value: 200, unit: 'pax' }],
  },
  {
    id: 'xy',
    cat: 'catering',
    name: 'BRASSERIE',
    description:
      'Looking for a location for a business lunch, meeting or a small-scale Christmas get-together? Then the Brasserie is the ideal place for you. Thanks to its integrated bar, the Brasserie is the perfect setting for welcome receptions.',
    specs: [{ id: 'people', label: '', value: 30, unit: 'pax' }],
  },
  {
    id: 'xy',
    cat: 'catering',
    name: 'CLUB',
    description:
      'Large floor-to-ceiling windows bathe the space in oceans of daylight. Book this atmospheric location for your meeting or standing-room only reception. For larger events the Club can be linked up with the Brasserie.',
    specs: [{ id: 'people', label: '', value: 50, unit: 'pax' }],
  },
  {
    id: 'xy',
    cat: 'catering',
    name: 'PIAZZA',
    description:
      'Visit us at the Piazza self-service restaurant on the first floor of the Congress Center, where a wide selection of delicious appetisers, fresh salads and nutritious warm meals await.',
    specs: [{ id: 'people', label: '', value: 1234, unit: 'pax' }],
  },
  {
    id: 'xy',
    cat: 'catering',
    name: 'PAVILLON',
    description:
      'Take a seat in the Pavillon self-service restaurant between Halls A and B and indulge yourself with a delicious Viennese melange coffee with a piece of cake from Gerstner’s master confectioners, or delicious Gerstner sausage with mustard, horseradish and a Viennese Semmel bread roll.',
    specs: [{ id: 'people', label: '', value: 12, unit: 'pax' }],
  },
  {
    id: 'xy',
    cat: 'catering',
    name: 'UPTOWN',
    description:
      'Enjoy the views of the exhibition from the Uptown self-service restaurant in Hall B – as well as the delicious dishes served fresh on your plate. You can look forward to a fine selection of starters, fresh salad from the buffet, soups and warm main courses.',
    specs: [{ id: 'people', label: '', value: 13, unit: 'pax' }],
  },
];

export const venueLocation = [
  {
    id: 'xy',
    cat: 'parking',
    label: 'Venue Parking',
    specs: [
      { label: 'Capacity', value: 1000, unit: '' },
      { label: 'Price', value: 0, unit: 'free' },
      { label: 'Distance', value: 50, unit: 'm' },
    ],
  },
  {
    id: 'xy',
    cat: 'parking',
    label: 'Wilson Parking',
    specs: [
      { label: 'Capacity', value: 500, unit: '' },
      { label: 'Price', value: 2, unit: '$ / hr' },
      { label: 'Distance', value: 150, unit: 'm' },
    ],
  },
  {
    id: 'xy',
    cat: 'parking',
    label: 'Best Car Parking',
    specs: [
      { label: 'Capacity', value: 250, unit: '' },
      { label: 'Price', value: 1.5, unit: '$ / hr' },
      { label: 'Distance', value: 350, unit: 'm' },
    ],
  },
  {
    id: 'xy',
    cat: 'parking',
    label: 'Mega Parking',
    specs: [
      { label: 'Capacity', value: 10000, unit: '' },
      { label: 'Price', value: 1, unit: '$ / hr' },
      { label: 'Distance', value: 400, unit: 'm' },
    ],
  },
  {
    id: 'xy',
    cat: 'transport',
    label: 'Venue Train Station',
    specs: [
      { label: 'Type', value: 'Train', unit: '' },
      { label: 'Distance', value: 50, unit: 'm' },
    ],
  },
  {
    id: 'xy',
    cat: 'transport',
    label: 'Cab Rink',
    specs: [
      { label: 'Type', value: 'Cab', unit: '' },
      { label: 'Distance', value: 100, unit: 'm' },
    ],
  },
  {
    id: 'xy',
    cat: 'transport',
    label: 'Bus Stop',
    specs: [
      { label: 'Type', value: 'Bus', unit: '' },
      { label: 'Distance', value: 250, unit: 'm' },
    ],
  },
  {
    id: 'xy',
    cat: 'transport',
    label: 'Shenzhen International',
    specs: [
      { label: 'Type', value: 'Airport', unit: '' },
      { label: 'Distance', value: 5700, unit: 'm' },
    ],
  },
];

export const venueRatings = [
  {
    id: 'infrastructure',
    label: 'Infrastructure',
    specs: [
      { id: 'appearance', label: 'Appearance' },
      { id: 'wayfinding', label: 'Wayfinding' },
      { id: 'wifi', label: 'Wi-Fi' },
    ],
  },
  {
    id: 'destination',
    label: 'Destination',
    specs: [
      { id: 'parking', label: 'Parking' },
      { id: 'hotels', label: 'Nearby Hotels' },
      { id: 'access', label: 'Ease of access' },
      { id: 'transport', label: 'Local transport' },
    ],
  },
  {
    id: 'organizers',
    label: 'Management (Organizers)',
    specs: [
      { id: 'engagement', label: 'Engagement' },
      { id: 'support', label: 'Support' },
      { id: 'flexibility', label: 'Flexibility' },
      { id: 'reachability', label: 'Reachability' },
    ],
  },
  {
    id: 'exhibitors',
    label: 'Logistics (Exhibitors)',
    specs: [
      { id: 'gates', label: 'Gates' },
      { id: 'rigging', label: 'Rigging' },
      { id: 'map', label: 'Map Information' },
    ],
  },
];

export const venueFeedback = [
  {
    role: 'visitor',
    rating: 4.5,
    date: '27 July 2021',
    topic: 'Attend event in June 2021',
    hl: 'Headline is featured here',
    p: 'Very nice location. The whole event was very well organized. The airport is a bit far.',
  },
  {
    role: 'organizer',
    rating: 5,
    date: '26 July 2021',
    topic: 'Attend event in June 2021',
    hl: 'Headline is featured here',
    p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce laoreet risus massa, non consectetur massa consectetur ut. „Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce laoreet risus massa, non consectetur massa consectetur ut. Etiam non ligula sed est egestas dictum. .“Etiam non ligula sed est egestas dictum. .',
  },
  {
    role: 'exhibitor',
    rating: 4.1,
    date: '25 July 2021',
    topic: 'Attend event in June 2021',
    hl: 'Headline is featured here',
    p: 'Very nice location. The whole event was very well organized. The airport is a bit far.',
  },
  {
    role: 'visitor',
    rating: 4.4,
    date: '24 July 2021',
    topic: 'Attend event in June 2021',
    hl: 'Headline is featured here',
    p: 'Very nice location. The whole event was very well organized. The airport is a bit far.',
  },
  {
    role: 'organizer',
    rating: 4.3,
    date: '26 July 2021',
    topic: 'Attend event in June 2021',
    hl: 'Headline is featured here',
    p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce laoreet risus massa, non consectetur massa consectetur ut. „Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce laoreet risus massa, non consectetur massa consectetur ut. Etiam non ligula sed est egestas dictum. .“Etiam non ligula sed est egestas dictum. .',
  },
  {
    role: 'exhibitor',
    rating: 4.1,
    date: '25 July 2021',
    topic: 'Attend event in June 2021',
    hl: 'Headline is featured here',
    p: 'Very nice location. The whole event was very well organized. The airport is a bit far.',
  },
];

export const venueHallsData = [
  {
    id: 'hall-2',
    cat: 'Halls',
    name: 'Exhibition Hall 1',
    specs: [
      { id: 'space', label: '', value: 2000, unit: 'sqm' },
      { id: 'dimensions', label: '', value: '230 m / 50 m / 4 m', unit: '' },
      { id: 'people', label: '', value: 498, unit: 'pax' },
      { id: 'floors', label: '', value: 3, unit: '' },
    ],
  },
  {
    id: 'hall-1',
    cat: 'Halls',
    name: 'Exhibition Hall 2',
    specs: [
      { id: 'space', label: '', value: 1900, unit: 'sqm' },
      { id: 'dimensions', label: '', value: '160 m / 56 m / 6 m', unit: '' },
      { id: 'people', label: '', value: 300, unit: 'pax' },
      { id: 'floors', label: '', value: 1, unit: '' },
    ],
  },
  {
    id: 'hall-1',
    cat: 'Halls',
    name: 'Exhibition Hall 3',
    specs: [
      { id: 'space', label: '', value: 1880, unit: 'sqm' },
      { id: 'dimensions', label: '', value: '234 m / 72 m / 4 m', unit: '' },
      { id: 'people', label: '', value: 460, unit: 'pax' },
      { id: 'floors', label: '', value: 1, unit: '' },
    ],
  },
  {
    id: 'hall-1',
    cat: 'Halls',
    name: 'Exhibition Hall 4',
    specs: [
      { id: 'space', label: '', value: 2200, unit: 'sqm' },
      { id: 'dimensions', label: '', value: '204 m / 43 m / 7 m', unit: '' },
      { id: 'people', label: '', value: 500, unit: 'pax' },
      { id: 'floors', label: '', value: 4, unit: '' },
    ],
  },
  {
    id: 'hall-1',
    cat: 'Rooms',
    name: 'Congress Room 1',
    specs: [
      { id: 'space', label: '', value: 200, unit: 'sqm' },
      { id: 'dimensions', label: '', value: '60 m / 56 m / 6 m', unit: '' },
      { id: 'people', label: '', value: 300, unit: 'pax' },
      { id: 'floors', label: '', value: 1, unit: '' },
    ],
  },
  {
    id: 'hall-1',
    cat: 'Rooms',
    name: 'Congress Room 2',
    specs: [
      { id: 'space', label: '', value: 380, unit: 'sqm' },
      { id: 'dimensions', label: '', value: '34 m / 72 m / 4 m', unit: '' },
      { id: 'people', label: '', value: 460, unit: 'pax' },
      { id: 'floors', label: '', value: 1, unit: '' },
    ],
  },
  {
    id: 'hall-1',
    cat: 'Rooms',
    name: 'Congress Room 3',
    specs: [
      { id: 'space', label: '', value: 240, unit: 'sqm' },
      { id: 'dimensions', label: '', value: '24 m / 43 m / 7 m', unit: '' },
      { id: 'people', label: '', value: 500, unit: 'pax' },
      { id: 'floors', label: '', value: 4, unit: '' },
    ],
  },
];

export const venueHotelsData = [
  {
    id: 'hotel-id-1',
    cat: 'Hotels',
    name: 'Hotel Presidente',
    country: 'China',
    state: '',
    city: 'Beijing',
    status: 'promoted',
    thumbs: ['https://via.placeholder.com/400x277/dcecf4/'],
    rating: 4.5,
    rates: 15,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'hotel-id-2',
    cat: 'Hotels',
    name: 'Regina Palace Hotel',
    country: 'China',
    state: '',
    city: 'Beijing',
    status: 'approved',
    thumbs: ['https://via.placeholder.com/400x277/dcecf4/'],
    rating: 4.5,
    rates: 12,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'hotel-id-3',
    cat: 'Hotels',
    name: 'Random Small Hotel',
    country: 'China',
    state: '',
    city: 'Beijing',
    status: 'approved',
    thumbs: ['https://via.placeholder.com/400x277/dcecf4/'],
    rating: 4.5,
    rates: 5,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'hotel-id-4',
    cat: 'Hotels',
    name: 'Random Small Hotel',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 4.4,
    rates: 23,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'hotel-id-5',
    cat: 'Hotels',
    name: 'Random Small Hotel',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 4.1,
    rates: 12,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
];

export const venueServicesData = [
  {
    id: 'service-id-1',
    cat: 'Catering',
    name: 'Random Service Provider',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 4.2,
    rates: 19,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'service-id-2',
    cat: 'Catering',
    name: 'Random Service Provider',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 3.5,
    rates: 14,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'service-id-3',
    cat: 'Catering',
    name: 'Random Service Provider',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 3.2,
    rates: 15,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'service-id-3',
    cat: 'Catering',
    name: 'Random Service Provider',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 3.6,
    rates: 9,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'service-id-3',
    cat: 'Rigging',
    name: 'Random Service Provider',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 5,
    rates: 15,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'service-id-3',
    cat: 'Rigging',
    name: 'Random Service Provider',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 3.2,
    rates: 15,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
  {
    id: 'service-id-3',
    cat: 'Rigging',
    name: 'Random Service Provider',
    country: 'China',
    state: 'Beijing',
    city: 'Beijing',
    status: '',
    thumbs: [],
    rating: 4.8,
    rates: 25,
    specs: [
      { id: 'space', label: '', value: 72000, unit: 'qm' },
      { id: 'people', label: '', value: 120000, unit: 'ppl' },
    ],
  },
];

/*
 * Search
 */
export const inputData = [
  { tag: 'location', label: 'Everywhere', value: '', bold: true },
  { tag: 'location', label: 'Your location', value: '' },
  { tag: 'location', label: 'Africa', value: '' },
  { tag: 'location', label: 'Asia', value: '' },
  { tag: 'location', label: 'Australia', value: '' },
  { tag: 'location', label: 'Europe', value: '' },
  { tag: 'location', label: 'North America', value: '' },
  { tag: 'location', label: 'South America', value: '' },
];
export const inputData2 = [
  {
    tag: 'location',
    locale: 'New York',
    label: 'New York City',
    value: 'new-york',
  },
  {
    tag: 'location',
    locale: 'New York, NY',
    label: 'New York, Manhattan',
    value: 'new-york',
  },
  {
    tag: 'location',
    locale: 'Louisiana',
    label: 'New Orleans',
    value: 'new-york',
  },
  {
    tag: 'venue',
    locale: 'New York, NY',
    label: 'Javis Center New York',
    value: 'new-york',
  },
  {
    tag: 'venue',
    locale: 'New York, NY',
    label: 'The New York Expo Center',
    value: 'new-york',
  },
  {
    tag: 'service',
    locale: 'New York, NY',
    label: 'Brooklyn Catering Service',
    value: 'hotel1',
  },
  {
    tag: 'service',
    locale: 'New York, NY',
    label: 'Srade Fair Logistics',
    value: 'hotel2',
  },
  // {tag: "hotel", locale:"New York, NY", label: "Four Seasons NY Downtown", value:"a-nearby-venue"},
  // {tag: "hotel", locale:"New York, NY", label: "NY DoubleTree by Hilton Hotel", value:"another-venue"},
];

export const resultsFilterData = [
  {
    type: 'location',
    label: 'New York, New York',
    value: 'new york city',
    unit: 'city',
    uid: '9999999991',
  },
  {
    type: 'category',
    label: 'Venues',
    value: 'venues',
    unit: 'entry',
    uid: '9999999992',
  },
  {
    type: 'distance',
    label: 'Radius',
    value: 10,
    unit: 'km',
    uid: '9999999993',
  },
  {
    type: 'location',
    label: 'New York, New York',
    value: 'new york city',
    unit: 'city',
    uid: '9999999991',
  },
  {
    type: 'category',
    label: 'Venues',
    value: 'venues',
    unit: 'entry',
    uid: '9999999992',
  },
  {
    type: 'distance',
    label: 'Radius',
    value: 10,
    unit: 'km',
    uid: '9999999993',
  },
];
