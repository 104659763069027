import React, { useState, useEffect, MouseEvent } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import clsx from 'clsx';
import Swipe from 'react-easy-swipe';
import Button from '../atoms/Button';
import { tutorials } from '../../data/constants';
import styles from '../../styles/components/organisms/Tutorial.module.scss';

interface TutorialProps {
  pageKey: string;
  className?: string;
  setLayer: () => void;
}

export default function Tutorial(props: TutorialProps) {
  const { t } = useTranslation(['common', 'tutorials']);
  const [step, setStep] = useState(0);
  const { pageKey, setLayer } = props;
  const stepKeys = tutorials[pageKey] || [];
  const steps = stepKeys.map((key: string, i: number) => ({
    title: t(`tutorials:${pageKey}:title`),
    video: `step${i + 1}-${key}.mp4`,
    h: t(`tutorials:${pageKey}:step-${i + 1}:h`),
    p: (
      <Trans
        i18nKey={`tutorials:${pageKey}:step-${i + 1}:p`}
        // defaults="We will see you at <bold>{{city}}</bold> in the morning!"
        // values={{ city: 'NEW YORK' }}
        components={{ bold: <b /> }}
      />
    ),
  }));
  const { title, video, h, p } = steps[step];
  const lastStep = step >= steps.length - 1;
  const classes = clsx(styles.tutorial, styles[`step-"{step}`]);

  useEffect(() => {
    console.log('jo', video);
    document.getElementsByTagName(
      'video'
    )[0].src = `/assets/tutorials/${pageKey}/${video}`;
  }, [video, pageKey]);

  return (
    <div className={classes}>
      <div>
        <h2>{title}</h2>
        {!!video && (
          <video
            autoPlay
            loop
            muted
            onLoad={(e) => {
              e.currentTarget.play();
            }}
            onClick={(e) => {
              e.currentTarget.play();
            }}
          >
            <source src={`/assets/tutorials/${pageKey}/${video}`} />
          </video>
        )}
        <p>
          <b>{h}</b>
          <br />
          {p}
        </p>
      </div>
      <div>
        <Button
          half
          float
          medium
          iconLeft
          label={t(`common:cta-back`)}
          disabled={step === 0}
          onClick={() => {
            setStep(step - 1);
          }}
        />
        <Button
          half
          float
          medium
          dark={lastStep}
          label={
            lastStep ? t(`tutorials:${pageKey}:cta-done`) : t(`common:cta-next`)
          }
          onClick={() => {
            lastStep ? setLayer() : setStep(step + 1);
          }}
        />
      </div>

      <ul>
        {steps.map((el: any, i: number) => (
          <li key={i} className={step === i ? styles.active : ''}>
            <button onClick={() => setStep(i)} />
          </li>
        ))}
      </ul>
    </div>
  );
}
