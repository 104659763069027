import clsx from 'clsx';
import styles from '../../styles/components/atoms/Loading.module.scss';

interface LoadingProps {
  className?: string;
  subtl1?: boolean;
  bg?: boolean;
  overlay?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
}

export default function Loading(props: LoadingProps) {
  const { bg, overlay, subtl1, className, large, medium, small } = props;
  const classes = clsx(
    styles.loading,
    { [styles.bg]: bg, [styles.overlay]: overlay },
    className
  );

  return (
    <div
      className={classes}
      style={
        large
          ? { width: '3rem', height: '3rem' }
          : medium
          ? { width: '2rem', height: '2rem' }
          : small
          ? { width: '1.5rem', height: '1.5rem' }
          : {}
      }
    >
      <svg viewBox="0 0 64 64">
        <path
          fill="#FFFFFF"
          vectorEffect="non-scaling-stroke"
          d="M55.96,64H8.04C3.6,64,0,60.4,0,55.96V8c0-4.42,3.58-8,8-8h47.96C60.4,0,64,3.6,64,8.04v47.93C64,60.4,60.4,64,55.96,64z"
        />
        <path
          fill="none"
          stroke={subtl1 ? '#AEBDC4' : '#001640'}
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          d="M11.7,18.57l15.92,27.75c0.89,1.57,2.56,2.55,4.37,2.55l0,0c1.81-0.01,3.48-0.98,4.37-2.55L52.3,18.57c1.7-2.95,0.95-6.7-1.75-8.78l0,0c-3.49-2.66-8.51-1.23-10.13,2.89l-3.1,7.88c-1.92,4.89-8.73,4.89-10.65,0l-3.1-7.88c-1.62-4.11-6.64-5.55-10.13-2.89l0,0C10.75,11.86,10.01,15.61,11.7,18.57z"
        />
        <path
          fill="none"
          stroke={subtl1 ? '#AEBDC4' : '#001640'}
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          d="M38.47,42c5.96,1.07,10.15,3.58,10.15,6.5c0,3.9-7.44,7.06-16.62,7.06s-16.62-3.16-16.62-7.06c0-2.87,4.02-5.33,9.8-6.44"
        />
      </svg>
    </div>
  );
}
