import React, { useState, useEffect, MouseEvent } from 'react';
import clsx from 'clsx';
import Icon from './Icon';
import styles from '../../styles/components/atoms/Checkbox.module.scss';

interface CheckboxProps {
  className?: string;
  // onChange?: (e: MouseEvent<HTMLButtonElement>) => void,
  onChange?: (e: any) => void;
  name: string;
  format?: string;
  icon?: string;
  label?: any;
  info?: string;
  defaultValue?: boolean | string;
  placeholder?: string;
  center?: boolean;
  dark?: boolean;
  grey?: boolean;
  white?: boolean;
  full?: boolean;
  half?: boolean;
  third?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  float?: boolean;
  add?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  optional?: boolean;
  error?: boolean;
}

export default function Checkbox(props: CheckboxProps) {
  const [value, setValue] = useState(false);
  const [currError, setCurrError] = useState(false);
  const {
    name,
    error,
    optional,
    invalid,
    disabled,
    center,
    dark,
    grey,
    white,
    defaultValue,
    onChange,
    label,
    float,
    half,
    third,
    full,
    medium,
    large,
    className,
  } = props;
  const size = large ? 'large' : medium ? 'medium' : 'small';
  const color = dark ? 'dark' : grey ? 'grey' : white ? 'white' : 'transparent';
  const { checkbox } = styles;
  const classes = clsx(
    checkbox,
    styles[size],
    styles[color],
    { [styles.disabled]: disabled },
    { [styles.float]: float },
    { [styles.center]: center },
    { [styles.error]: currError || invalid },
    { [styles.third]: !half && !full && third },
    { [styles.half]: !full && !third && half },
    { [styles.full]: !half && !third && full },
    className
  );

  const handleChange = (e: any) => {
    if (onChange) {
      onChange({ [name]: !value });
    }
    setValue(!value);
  };

  const handleClick = (e: any) => {
    handleChange(e);
    // setValue(!value);
    // // e.preventDefault();
    // const field = document.querySelector(
    //   'input[name=' + name + ']'
    // ) as HTMLInputElement;
    // console.log(field);
    // if (!field) {
    //   return;
    // }
    // field.click();
  };

  useEffect(() => {
    setValue(!!defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setCurrError(!!error);
  }, [error]);

  return (
    <div className={classes}>
      <input
        data-checked={!!value}
        data-format={'boolean'}
        type="checkbox"
        name={name}
        required={!optional}
        onChange={handleChange}
        checked={!!value}
      />
      <label htmlFor={name} onClick={handleClick}>
        {label}
      </label>
    </div>
  );
}
