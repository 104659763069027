import React, { useState, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useAuth } from '../../context/AuthUserContext';
import Button from '../atoms/Button';
import Icon from '../atoms/Icon';
import Figure from '../molecules/Figure';
import Navigation from '../molecules/Navigation';
import Meta from '../molecules/Meta';
import { toggleScroll } from '../../lib/utils';
import styles from '../../styles/components/organisms/OffCanvas.module.scss';

interface OffCanvasProps {
  className?: string;
  user?: any;
  toggle: (el?: string) => void;
  isOpen: boolean;
  scrolled: boolean;
  dark: boolean;
  noBtn?: boolean;
}

export default function OffCanvas(props: OffCanvasProps) {
  const { t } = useTranslation(['common', 'pages']);
  const router = useRouter();
  const { user, rfpCount, logOut } = useAuth();
  const [open, setOpen] = useState(false);
  const { toggle, dark, isOpen, scrolled, noBtn } = props;
  const { name, email } = (user as any) || {};
  const classes = clsx(
    'offcanvas',
    styles.offcanvas,
    { [styles.open]: open },
    { [styles.scrolled]: scrolled },
    { [styles.no_btn]: !!toggle }
  );

  const mainMenu = [
    { path: `/`, label: t('pages:home:navi') },
    {
      path: `/what-is-venueScout`,
      label: t('pages:what-is-venueScout:navi'),
    },
    {
      path: `/promote-your-business`,
      label: t(`pages:promote-your-business:navi`),
    },
  ].filter((el) => !!el) as any;

  const userMenu = [
    !!user
      ? {
          path: `/my-inbox`,
          label: t('pages:my-inbox:navi'),
          icon: (
            <Icon
              name="inbox"
              options={!!rfpCount ? { count: rfpCount.toString() } : undefined}
            />
          ),
        }
      : null,
    {
      path: `/promote-your-business/create`,
      label: t('pages:add-business:navi'),
    },
    {
      path: `/my-businesses`,
      label: t('pages:my-businesses:navi'),
    },
    { path: `/my-account`, label: t('pages:my-account:navi') },
  ].filter((el) => !!el) as any;

  const footerMenu = [
    { path: `/about`, label: t('pages:about:navi') },
    { path: `/contact`, label: t('pages:contact:navi') },
    { path: `/faq`, label: t(`pages:faq:navi`) },
  ];

  const toggleState = () => {
    toggleScroll(open);
    if (!!toggle) {
      toggle('canvas');
    } else {
      setOpen(!open);
    }
  };

  const handleClick = (event: any) => {
    const left = false;
    const oc = document.querySelectorAll('.offcanvas')[0];
    const ocDiv = oc.getElementsByTagName('DIV')[0];
    let outX = left
      ? window.innerWidth / 2
      : window.innerWidth - window.innerWidth / 2;
    if (ocDiv instanceof HTMLElement) {
      outX = left
        ? ocDiv.offsetWidth || window.innerWidth / 2
        : window.innerWidth - ocDiv.offsetWidth;
    }
    const isOut = left ? event.pageX > outX : event.pageX < outX;
    if (open && isOut) {
      toggleState();
    } else {
      toggleState();
    }
  };

  const signOut = () => {
    toggle('canvas');
    if (!!router.query.mode) {
      router.replace(router.asPath.split('?')[0], undefined, {
        shallow: true,
      });
    }
    logOut();
  };

  useEffect(() => {
    toggleScroll(!isOpen);
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div className={classes} onClick={handleClick}>
      {!noBtn && (
        <Button
          className={styles.btn}
          onClick={toggleState}
          icon={open ? 'close' : 'burger'}
        />
      )}
      <div>
        <Meta openCanvas dark={dark} toggle={toggle} />

        {!!user ? (
          <>
            <Figure
              tiny
              round
              href={`/my-account`}
              caption={`${t('common:welcome')} ${name || email}`}
            />
            <Navigation vertical data={userMenu} />
            <br />
            <br />
            <Button
              medium
              label={t('common:cta-log-out')}
              icon="logout"
              onClick={signOut}
            />
            <Navigation vertical small data={mainMenu} />
          </>
        ) : (
          <>
            <Navigation vertical data={mainMenu} />
            <br />
            <br />
            <Button
              medium
              icon="login"
              label={t('common:cta-sign-in-or-up')}
              onClick={() => toggle('login')}
            />
          </>
        )}

        <Navigation vertical small data={footerMenu} />
      </div>
    </div>
  );
}
