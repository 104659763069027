import React, { useState, useEffect, useCallback, FormEvent } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import { getFormData, getFieldErrors } from '../../lib/utils';
import Loading from '../atoms/Loading';
import Button from '../atoms/Button';
import styles from '../../styles/components/molecules/Form.module.scss';

interface FormProps {
  id: string;
  error?: any;
  loading?: boolean;
  children?: any;
  append?: any;
  title?: string;
  float?: boolean;
  center?: boolean;
  checkOnce?: boolean;
  className?: string;
  submitLabel?: string;
  submitIcon?: string;
  prefill?: any;
  onCancel?: () => void;
  onFieldError?: (errors: string[]) => void;
  onSubmit?: (data: any) => void;
  onChange?: (data: any) => void;
}

const Form = (props: FormProps) => {
  const { t } = useTranslation(['common']);
  const [formError, setFormError] = useState() as any;
  const {
    id,
    children,
    append,
    error,
    loading,
    float,
    center,
    checkOnce,
    className,
    submitLabel,
    submitIcon,
    onFieldError,
    onSubmit,
    onCancel,
    onChange,
  } = props;
  const classes = clsx(
    styles.form,
    { [styles.loading]: loading },
    { [styles.float]: float },
    { [styles.center]: center },
    { [styles.disabled]: loading },
    className
  );

  /**
   * Handle form input
   */
  const validFields = (get?: boolean) => {
    setFormError('');
    const fieldErrors = getFieldErrors(id);

    if (!!fieldErrors.length) {
      setFormError(t('common:form-input-error'));
    }
    if (!!onFieldError) {
      onFieldError(fieldErrors);
    }
    if (get) {
      return !!!fieldErrors.length;
    }
  };

  /**
   * Handle form change
   */
  const handleChange = (e: FormEvent) => {
    // if (!checkOnce) {
    //   validFields();
    // }

    if (!!onChange) {
      const formData = getFormData(id) as any;
      onChange(formData);
    }
    if (checkOnce && !formError) return;
    validFields();
  };

  /**
   * Handle form submit
   */
  const submit = (e: FormEvent) => {
    e.preventDefault();
    if (!validFields(true)) {
      return;
    }
    const formData = getFormData(id);
    setFormError('');
    if (!!onSubmit) {
      onSubmit(formData);
    }
  };

  useEffect(() => {
    setFormError(error);
  }, [error]);

  return (
    <>
      <form
        id={id}
        className={classes}
        onSubmit={submit}
        onChange={handleChange}
      >
        {children}

        <div className={styles.footer}>
          {formError && (
            <p>
              <small className={styles.alert}>{formError}</small>
            </p>
          )}
          <br />

          {!!onCancel && (
            <Button
              large
              white
              icon="cancel"
              label={t('common:cta-cancel')}
              type="reset"
              onClick={onCancel}
            />
          )}

          {!!onSubmit && (
            <Button
              large
              dark
              icon={submitIcon || undefined}
              label={submitLabel || t('common:cta-submit')}
              type="submit"
              onClick={submit}
            />
          )}
        </div>

        {append || ''}
      </form>
      {loading && <Loading overlay />}
    </>
  );
};

export default Form;
