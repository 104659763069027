import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Button from '../atoms/Button';
import styles from '../../styles/components/molecules/Toast.module.scss';

interface ToastProps {
  className?: string;
  closeBtn?: boolean;
  isOpen?: boolean;
  children: any;
}

export default function Toast(props: ToastProps) {
  const { children, closeBtn, isOpen, className } = props;
  const { toast } = styles;
  const [toggle, setToggle] = useState(true);
  const classes = clsx(toast, { [styles.active]: toggle }, className);

  useEffect(() => {
    setToggle(!!isOpen);
  }, [isOpen]);

  return (
    <div className={classes}>
      {children}
      {closeBtn && <Button icon="close" onClick={() => setToggle(!toggle)} />}
    </div>
  );
}
