import { ServiceProvider, Venue } from '@prisma/client';
import axios from 'axios';

type DataResultObject = {
  type: 'venue' | 'service-provider' | 'venue-space';
};

export type VenueResult = Venue & {
  distance: number;
} & DataResultObject;

export type ServiceResult = ServiceProvider & {
  distance: number;
} & DataResultObject;

export type CountResult = { count: string };

export type CountDataResult = {
  venues?: CountResult;
  services?: CountResult;
};

export async function getSearchData(
  query: NodeJS.Dict<string | string[]>,
  options?: {
    page?: number;
    imperial?: boolean;
  }
) {
  const response = await axios.get('/api/search', {
    params: { ...query, page: options?.page, imperial: options?.imperial },
  });
  return response.data;
}

export async function getMapMarkers(
  query: NodeJS.Dict<string | string[]>,
  options?: { imperial?: boolean }
) {
  const response = await axios.get('/api/search', {
    params: { ...query, map: true, imperial: options?.imperial },
  });
  return response.data;
}

export async function getSearchCount(
  query: NodeJS.Dict<string | string[]>,
  options?: { imperial?: boolean }
) {
  const response = await axios.get('/api/search', {
    params: { ...query, count: true, imperial: options?.imperial },
  });
  return response.data as CountDataResult;
}

export async function getVenueById(id: string) {
  const response = await axios.get(`/api/venue/${id}`);
  // const response = await axios.get(`/api/venue/cl1230olm00006tpiwnyv1fj9`);
  return response.data;
}
