export const defaultLocations = [
  {
    type: 'header',
    label: 'our-top-picks',
  },
  {
    type: 'location',
    label: 'United States',
    location: '',
    value: 'United States',
    lat: 39.7837304,
    lon: -100.445882,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'Germany',
    location: '',
    value: 'Germany',
    lat: 51.0834196,
    lon: 10.4234469,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'France',
    location: '',
    value: 'France',
    lat: 46.603354,
    lon: 1.8883335,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'Spain',
    location: '',
    value: 'Spain',
    lat: 39.3260685,
    lon: -4.8379791,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'United Kingdom',
    location: '',
    value: 'United Kingdom',
    lat: 54.7023545,
    lon: -3.2765753,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'Italy',
    location: '',
    value: 'Italy',
    lat: 42.6384261,
    lon: 12.674297,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'China',
    location: '',
    value: 'China',
    lat: 35.000074,
    lon: 104.999927,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'Netherlands',
    location: '',
    value: 'Netherlands',
    lat: 52.2434979,
    lon: 5.6343227,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'Portugal',
    location: '',
    value: 'Portugal',
    lat: 40.0332629,
    lon: -7.8896263,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'Canada',
    location: '',
    value: 'Canada',
    lat: 61.0666922,
    lon: -107.991707,
    location_type: 'country',
    // fillSearch: true,
  },
  {
    type: 'location',
    label: 'Worldwide',
    value: 'world',
    location_type: 'continent',
  },
];

export const continents = [
  {
    type: 'location',
    location_type: 'continent',
    label: 'World',
    value: 'World',
    lat: 1,
    lon: 1,
    polygon: [
      [-180, 180],
      [180, 180],
      [180, -180],
      [-180, -180],
      [-180, 180],
    ],
  },

  {
    type: 'location',
    location_type: 'continent',
    label: 'Europe',
    value: 'Europe',
    lat: 51.124213,
    lon: 14.898802,
    polygon: [
      [90, -10],
      [90, 77.5],
      [42.5, 48.8],
      [42.5, 30],
      [40.79, 28.81],
      [41, 29],
      [40.55, 27.31],
      [40.4, 26.75],
      [40.05, 26.36],
      [39.17, 25.19],
      [35.46, 27.91],
      [33, 27.5],
      [38, 10],
      [35.42, -10],
      [28.25, -13],
      [15, -30],
      [57.5, -37.5],
      [78.13, -10],
      [90, -10],
    ],
  },
  {
    type: 'location',
    location_type: 'continent',
    label: 'North America',
    value: 'North America',
    lat: 43.897892,
    lon: -99.446527,
    polygon: [
      [90, -168.75],
      [90, -10],
      [78.13, -10],
      [57.5, -37.5],
      [15, -30],
      [15, -75],
      [1.25, -82.5],
      [1.25, -105],
      [51, -180],
      [60, -180],
      [60, -168.75],
      [90, -168.75],
    ],
  },
  {
    type: 'location',
    location_type: 'continent',
    label: 'South America',
    value: 'South America',
    lat: -8.667918,
    lon: -59.275314,
    polygon: [
      [1.25, -105],
      [1.25, -82.5],
      [15, -75],
      [15, -30],
      [-60, -30],
      [-60, -105],
      [1.25, -105],
    ],
  },
  {
    type: 'location',
    location_type: 'continent',
    label: 'Oceania',
    value: 'Oceania',
    lat: -24.846565,
    lon: 134.836651,
    polygon: [
      [-11.88, 110],
      [-10.27, 140],
      [-10, 145],
      [-30, 161.25],
      [-52.5, 142.5],
      [-31.88, 110],
      [-11.88, 110],
    ],
  },
  {
    type: 'location',
    location_type: 'continent',
    label: 'Asia',
    value: 'Asia',
    lat: 32.990236,
    lon: 77.079717,
    polygon: [
      [90, 77.5],
      [42.5, 48.8],
      [42.5, 30],
      [40.79, 28.81],
      [41, 29],
      [40.55, 27.31],
      [40.4, 26.75],
      [40.05, 26.36],
      [39.17, 25.19],
      [35.46, 27.91],
      [33, 27.5],
      [31.74, 34.58],
      [29.54, 34.92],
      [27.78, 34.46],
      [11.3, 44.3],
      [12.5, 52],
      [-60, 75],
      [-60, 110],
      [-31.88, 110],
      [-11.88, 110],
      [-10.27, 140],
      [33.13, 140],
      [51, 166.6],
      [60, 180],
      [90, 180],
      [90, 77.5],
    ],
  },
  {
    type: 'location',
    location_type: 'continent',
    label: 'Africa',
    value: 'Africa',
    lat: 11.867351,
    lon: 21.008593,
    polygon: [
      [15, -30],
      [28.25, -13],
      [35.42, -10],
      [38, 10],
      [33, 27.5],
      [31.74, 34.58],
      [29.54, 34.92],
      [27.78, 34.46],
      [11.3, 44.3],
      [12.5, 52],
      [-60, 75],
      [-60, -30],
      [15, -30],
    ],
  },
];
