import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import styles from '../../styles/components/molecules/InputRange.module.scss';

interface InputRangeProps {
  className?: string;
  id: string;
  values: any[];
  defaultValues: any[];
  label: string;
  isActive?: boolean | false;
  onChange?: ({}) => void;
}

export default function InputRange(props: InputRangeProps) {
  const { inputRange } = styles;
  const { defaultValues, isActive, values, label, id, onChange } = props;
  const [enabled, setEnabled] = useState(false);
  const [active, setActive] = useState(isActive);
  const [focus, setFocus] = useState(false);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const minName = `${id}-min`;
  const maxName = `${id}-max`;
  const classes = clsx(inputRange, { [styles.active]: active });

  const invalid = (value: number) => {
    return (
      isNaN(value) || value >= defaultValues[1] || value <= defaultValues[0]
    );
  };

  const handleChange = useCallback((e: any) => {
    const value = parseInt(e.target.value);
    const name = e.target.name;
    const val = invalid(value) ? '' : (value as any);
    setEnabled(true);

    if (name === minName) {
      setActive(!!val || !!maxValue);
      setMinValue(val);
    }
    if (name === maxName) {
      setActive(!!val || !!minValue);
      setMaxValue(val);
    }
  }, []);

  const handleClick = (e: any) => {
    if (!focus && (minValue || maxValue)) {
      setEnabled(true);
      setActive(!active);
    }
  };

  useEffect(() => {
    setActive(!!isActive);
  }, [isActive]);

  useEffect(() => {
    const currMin = parseInt(values[0]) as any;
    const currMax = parseInt(values[1]) as any;
    if (currMin !== defaultValues[0]) {
      setMinValue(currMin);
    }
    if (currMax !== defaultValues[1]) {
      setMaxValue(currMax);
    }
  }, [defaultValues, values]);

  // Callback (only triggered on user interaction (enabled))
  useEffect(() => {
    if (enabled && !!onChange) {
      setEnabled(false);
      onChange({
        id,
        active,
        values: [minValue || defaultValues[0], maxValue || defaultValues[1]],
      });
    }
  }, [enabled, id, active, minValue, maxValue, defaultValues, onChange]);

  useEffect(() => {
    const minVal = parseInt(values[0]) as number;
    const maxVal = parseInt(values[1]) as number;

    if (minVal === defaultValues[0] && maxVal == defaultValues[1]) {
      setMinValue('');
      setMaxValue('');
      setActive(false);
    } else {
      setMinValue(minVal.toString());
      setMaxValue(maxVal.toString());
      setActive(true);
    }
  }, [defaultValues, values]);

  return (
    <div className={classes} onClick={handleClick}>
      <fieldset>
        <legend>{label}</legend>
        <input
          type="text"
          name={minName}
          value={minValue}
          placeholder={defaultValues[0]}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={handleChange}
          autoComplete="off"
        />
        <input
          type="text"
          name={maxName}
          value={maxValue}
          placeholder={defaultValues[1]}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={handleChange}
          autoComplete="off"
        />
      </fieldset>
    </div>
  );
}
