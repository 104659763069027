import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { searchOptions } from '../../data/constants';
import InputRange from '../molecules/InputRange';
import InputToggle from '../molecules/InputToggle';
import styles from '../../styles/components/organisms/SearchOptions.module.scss';
import { isMetric } from '../../features/metrics/metricsSlice';
import { useAppSelector } from '../../app/hooks';
import { metricUnitKeys } from '../../lib/units';
import SearchTypeCheck from '../molecules/SearchTypeCheck';
import { useRouter } from 'next/router';
import { useApp } from '../../context/AppContext';

interface SearchOptionsProps {
  className?: string;
  query?: any;
  values?: any;
  onChange: (e: any) => void;
  children?: React.ReactNode;
}

export default function SearchOptions(props: SearchOptionsProps) {
  const { devMode } = useApp();
  const { t } = useTranslation(['common', 'venue', 'service']);
  const { float, checkTypes, searchProps } = styles;
  const { onChange, values, query, children } = props;
  const [tab, setTab] = useState<'venues' | 'services'>('venues');
  const tabs = Object.keys(searchOptions);
  const metricState = useAppSelector(isMetric);
  const tabClasses = clsx(float, checkTypes, styles[`types-${tabs.length}`]);
  const propClasses = clsx(float, searchProps);
  const router = useRouter();

  const onPropChange = (obj: any) => {
    const { value, checked } = obj;
    if (tabs.indexOf(value) > -1 && value !== tab && checked) {
      setTab(value);
    }
    onChange({ ...obj, tab });
  };

  const renderedTabs = tabs.map((el: any, i: number) => {
    return (
      <SearchTypeCheck
        key={i}
        id={`searchtype-${el}`}
        isActive={tab === el}
        onClick={() => {
          setTab(el);
        }}
        onChange={onPropChange}
        label={t(`common:${el}`) || el}
        value={el}
      />
    );
  });

  const renderedOptions = Object.keys(searchOptions[tab]).map(
    (key: string, i: number) => {
      const initialValue = searchOptions[tab][key].value;
      const currKey = `${tab}_${key}`;
      const currValue = values[currKey];
      const tFile = tab.substring(0, tab.length - 1);
      const unitSystem = metricState ? 'metric' : 'imperial';
      const unit = searchOptions[tab][key].unit;
      const unitLabel = (unit && metricUnitKeys[unitSystem][unit]) || unit;
      const translatedUnitLabel = unitLabel && t(`common:${unitLabel}`);
      const label =
        tFile === 'service'
          ? t(`${tFile}:categories:${key}`)
          : t(`${tFile}:${key}`) +
            (translatedUnitLabel ? ` (${translatedUnitLabel})` : '');
      const active =
        (!!currValue && currValue.indexOf('#') === -1) || currKey in values;
      if (Array.isArray(initialValue)) {
        const values = !!currValue
          ? currValue.replace('#', '').split(',')
          : initialValue;
        return (
          <InputRange
            key={i}
            id={key}
            label={label}
            defaultValues={initialValue}
            values={values}
            onChange={onPropChange}
            isActive={active}
          />
        );
      } else {
        const toggleValues = ['true', 'false'].map((el: any) =>
          t(`common:${el}`)
        );
        const currChecked = !!currValue && currValue.indexOf('true') > -1;
        return (
          <InputToggle
            key={i}
            id={key}
            label={label}
            values={toggleValues}
            onChange={onPropChange}
            isActive={active}
            isChecked={currChecked}
          />
        );
      }
    }
  );

  useEffect(() => {
    if (values.types === 'services' || values.types === 'venues') {
      setTab(values.types);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.types, router.pathname]);

  return (
    <div className={styles.searchOptions}>
      <div className="d-flex">
        <div className={tabClasses}>{renderedTabs}</div>
        <div className={propClasses}>
          <p>{t(`common:search-options:p`)}</p>
          {renderedOptions}
        </div>
        {children}
      </div>
    </div>
  );
}
