import React, { useState, useEffect } from "react";
import clsx from "clsx";
import styles from "../../styles/components/molecules/InputToggle.module.scss";

interface InputToggleProps {
  className?: string;
  id: string;
  label: string;
  values: any[];
  isChecked?: boolean;
  isActive?: boolean;
  onChange?: ({}) => void;
}

export default function InputToggle(props: InputToggleProps) {
  const { inputToggle } = styles;
  const { id, label, values, isChecked, isActive, onChange } = props;
  const [enabled, setEnabled] = useState(false);
  const [active, setActive] = useState(isActive);
  const [checked, setChecked] = useState(isChecked);
  const classes = clsx(inputToggle, { [styles.active]: active });

  // const handleChange = (e: any) => {
  //   // e.stopPropagation();
  //   setEnabled(true);
  //   if(active){
  //     e.stopPropagation();
  //   }
  //   setChecked(!checked);
  // }

  const handleClick = (e: any) => {
    e.stopPropagation();
    setEnabled(true);
    setActive(!active);
    setChecked(!checked);
  };

  // Callback (only triggered on user interaction (enabled))
  useEffect(() => {
    if (enabled && !!onChange) {
      setEnabled(false);
      onChange({ id, active, value: checked });
    }
  }, [enabled, id, active, checked, onChange]);

  useEffect(() => {
    setActive(!!isActive);
  }, [isActive]);

  useEffect(() => {
    setChecked(!!isChecked);
  }, [isChecked]);

  return (
    <div className={classes} onClick={handleClick}>
      <fieldset>
        <legend>{label}</legend>
        <input
          type="checkbox"
          name={id}
          checked={checked}
          onChange={handleClick}
        />
        <div className={styles.switch} onClick={handleClick}>
          {checked ? values[0] : values[1]}
        </div>
      </fieldset>
    </div>
  );
}
