import React, { useEffect } from 'react';
import clsx from 'clsx';
import Icon from '../atoms/Icon';
import { scrollToElement } from '../../lib/utils';
import styles from '../../styles/components/molecules/AutoComplete.module.scss';
import Loading from '../atoms/Loading';
import { useTranslation } from 'next-i18next';
import Row from 'react-bootstrap/Row';

interface AutoCompleteProps {
  className?: string;
  isOpen: boolean;
  onClick: (index: number) => void;
  data: any[];
  loading: boolean;
}

export default function AutoComplete(props: AutoCompleteProps) {
  const { t } = useTranslation(['common']);
  const { autocomplete } = styles;
  const { data = [], isOpen, onClick } = props;
  const classes = clsx(autocomplete, {
    [styles.open]: isOpen,
  });

  const itemToRender = (item: any, index: number) => {
    const { type, slug, label, location } = item;

    if (type === 'header') {
      return (
        <li key={index} className={styles.header}>
          {t(`common:${label}`)}
        </li>
      );
    }

    const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      onClick(index);
    };
    return (
      <li key={index} onClick={handleClick}>
        <a href={slug}>
          <Icon name={type} />
          <span>{label}</span>
          {!!location && <span>{location}</span>}
        </a>
      </li>
    );
  };

  const listLeft = [];
  const listRight = [];

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.type === 'service') {
      listRight.push(itemToRender(item, i));
    } else {
      listLeft.push(itemToRender(item, i));
    }
  }

  useEffect(() => {
    scrollToElement('suggestions');
  }, [isOpen]);

  const loader = (
    <div
      className={styles.loaderWrapper}
      style={{
        opacity: props.loading ? 1 : 0,
      }}
    >
      <Loading />
    </div>
  );

  return (
    <>
      <nav className={classes} role="search">
        {loader}
        {/* if no locations */}
        {!data.length && !props.loading ? (
          <div className={styles.noResults}>
            <p>{t(`common:no-results`)}</p>
          </div>
        ) : (
          <>
            <Row
              style={{
                opacity: props.loading ? 0 : 1,
              }}
            >
              {!!listLeft.length && (
                <div className={'col-xs-8 col-md-8 col-lg-4'}>
                  <ul className="suggestions">{listLeft}</ul>
                </div>
              )}
              {!!listRight.length && (
                <div className={'col-lg-4 d-none d-lg-block'}>
                  <ul className="suggestions">{listRight}</ul>
                </div>
              )}
            </Row>
          </>
        )}
      </nav>
    </>
  );
}
