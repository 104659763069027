import Image from 'next/image';
import Link from 'next/link';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Row';
import clsx from 'clsx';
import MediaImage from '../atoms/MediaImage';
import styles from '../../styles/components/organisms/Stage.module.scss';
import { cloudflareLoader } from '../atoms/Img';

interface StageProps {
  className?: string;
  hero?: boolean;
  slides: {
    src: any;
    alt: string;
    w?: number;
    h?: number;
    href?: string;
    cta?: string;
    caption?: string;
  }[];
}

const Stage = (props: StageProps) => {
  const { slides, hero, className } = props;
  const classes = clsx(styles.stage, { [styles.hero]: hero }, className);

  return (
    <div className={classes}>
      {(slides || []).map((el, index) => (
        <figure key={index}>
          {!!el.w ? (
            <Image
              // placeholder='blur'
              // blurDataURL={el.src}
              src={el.src}
              alt={el.alt}
              width={el.w}
              height={el.h}
              layout="responsive"
              priority
              loader={cloudflareLoader}
            />
          ) : (
            <MediaImage src={el.src} />
          )}
          {(!!el.caption || !!el.href) && (
            <figcaption>
              <Container>
                <Row>
                  <div className={'col-xs-8 col-md-6 offset-md-1'}>
                    <span>
                      {el.caption}
                      {!!el.href && (
                        <Link href={el.href}>{el.cta || el.href}</Link>
                      )}
                    </span>
                  </div>
                </Row>
              </Container>
            </figcaption>
          )}
        </figure>
      ))}
    </div>
  );
};

export default Stage;
